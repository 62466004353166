import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Pages/Home"

function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

  }, [location])

  return (
    <div className="App">
      <Header />
      <Outlet />
    </div>
  );
}

export default App;
