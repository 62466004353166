import "./style.scss"

const NewsLetterSection = () => {
    return (
        <>
            {/* desktop */}
            <div className="news-letter-section mt-5 d-none d-lg-block px-lg-6">
                <div className="px-5 bg-light py-5">
                    <div className="px-5">
                        <div className="py-5 bg-light px-5">
                            <h5>
                                Be part of the experience by joining our Newsletter.</h5>
                            <h5 className="mb-4">
                                Get information about events, products, rewards and many more.
                            </h5>

                            <div className="position-relative">
                                <input type="email" placeholder="Email Address" className="font-secondary form-control py-4 rounded-3" style={{ border: 2, borderStyle: "solid", borderColor: "#FCB5BD" }} />
                                <button className="btn btn-dark subscribe-btn px-5 rounded-3"> Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className="news-letter-section-sm mt-5 d-block d-lg-none">
                <div className="px-4 bg-light py-5">
                    <div className="text-center">
                        <div className="bg-light">
                            <h6 className="lh-base fw-medium">
                                Be part of the experience by joining our Newsletter.
                                Get information about events, products, rewards and many more.
                            </h6>

                            <div className="position-relative">
                                <input type="email" className="form-control py-3 rounded-4" placeholder="Email Adress" />
                            </div>
                            <button className="btn btn-dark subscribe-btn px-5 py-2 mt-4"> Subscribe</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}


export default NewsLetterSection;