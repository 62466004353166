import { DragDrop } from "../../Assets/Icons";

declare interface FileDrapDropProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string
    description: string
    fileCout?: number;
}


const FileDrapDrop: React.FC<FileDrapDropProps> = (props) => {
    return (
        <div className="file-drag-drop">
            <h6>{props.title} <span className="text-red">*</span></h6>
            <div className="rounded-3  w-100 px-2 py-3" style={{ border: "2px dashed black", backgroundColor: "#ebebeb" }}>
                <div className="rounded-3 text-center d-flex justify-content-center">
                    <div className="doc-image bg-white p-1 rounded-2 text-center d-inline">
                        <img src={`${DragDrop}`} alt="" />
                        {/* <DragDrop /> */}
                    </div>
                </div>
                <div className="mt-3 text-center">
                    <p className="fw-bold"><span className="text-red ">Upload</span> {props.description} </p>
                </div>
            </div>
        </div>
    )
}

export default FileDrapDrop;