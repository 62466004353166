import { WhatsAppIcon } from "../../../Assets/Icons";
import "./style.scss"

const WhatsAppButton = (props: { className?: string }) => {
    return (
        <button className={`app-btn  bg-white btn border-2 ${props.className}`}>
            <img width={24} height={24} src={WhatsAppIcon} style={{ position: "relative", left: -5 }} /> <span style={{ position: "relative", left: -7 }}> 09090512482 </span>
        </button>
    );
}

export default WhatsAppButton;