import { ContainerFluid } from "../../../Components/Container"
import TestmonialCard from "../../../Components/TestmonialCard"
import VideoCard from "../../../Components/VideoCard"
import "./style.scss";

export const testimonials = [
    {
        clientName: "Oluwakemi Joachim",
        clientLocation: "Lagos, Nigeria",
        clientComment: `This is the best banking app! I'm enjoying seamless services. The loan
                            rate is not outrageous compared to other banks.
                            I've made QuickFund my main bank account and
                            I am excited to refer all my friends!`
    },
    {
        clientName: "FeyBio Enterprise",
        clientLocation: "Lagos, Nigeria",
        clientComment: "I did not only get support for my business needs. The financial advise and mentoring have helped my business scale up and I have no reservations to recommend QuickFund to any growing SME like ours."
    },
    {
        clientName: "Banc Solution Nig Ltd",
        clientLocation: "Lagos, Nigeria",
        clientComment: "QuickFund has been so helpful to our company by providing a line of credit for our continued growth. Many thanks to the management for their support!"
    },
    {
        clientName: "Sarolaj Investments",
        clientLocation: "Lagos, Nigeria",
        clientComment: "My journey with QuickFund has been that of smooth sail and no stress of any kind. My financial requests are met with prompt response and detailed attention."
    },

]


const MVPSection = () => {
    return (
        <div className="mvp-section">
            <ContainerFluid className="px-lg-5 mt-5">
                <div>
                    <div className="d-none d-lg-block text-center">
                        <h1 className="fs-3 fw-bolder font-primary m-0 p-0 mt-4">Our Customers are the real <span className="font-secondary fw-bolder text-red mb-1"> MVPs </span></h1>
                        <h6 className="text-center mt-2 p-0 display-7"> We are trusted by our valued </h6>
                        <h6 className="text-center"> customers</h6>
                    </div>
                    <div className="d-block d-lg-none text0center">
                        <h1 className="fs-4 fw-bolder font-primary m-0 p-0 mt-4">Our Customers are the real <span className="font-secondary fw-bolder text-red mb-1"> MVPs </span></h1>
                        <h6 className="text-center mt-2 p-0 small"> We are trusted by our valued </h6>
                        <h6 className="text-center small">  customers</h6>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="d-none d-lg-flex col-12 col-lg-8 align-self-center row  position-relative gx-lg-5">
                        {
                            testimonials.map((testimonial) =>
                                <div key={testimonial.clientName} className="col-12 col-lg-6 mt-5">
                                    <TestmonialCard clientName={testimonial.clientName} clientComment={testimonial.clientComment} clientLocation={testimonial.clientLocation} />
                                </div>
                            )
                        }

                        <svg className="position-absolute" style={{ zIndex: -1, top: "10%" }} height="80%" viewBox="0 0 694 684" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M155.824 62.8474C212.912 22.5893 280.768 16.0646 347.173 7.5951C405.729 0.12682 465.23 -6.21856 516.282 16.8739C566.787 39.7184 603.873 82.8728 626.312 133.857C647.956 183.033 632.272 240.86 638.977 295.644C649.817 384.202 726.168 471.527 677.631 553.122C631.383 630.868 518.695 621.156 433.306 641.392C348.646 661.456 257.428 704.841 183.564 670.209C110.146 635.786 93.7189 542.274 61.7329 467.177C31.9427 397.235 -14.0472 328.291 4.31794 249.372C22.3884 171.719 89.0088 109.964 155.824 62.8474Z" fill="#FCB5BD" fillOpacity="0.37" />
                        </svg>

                    </div>

                    <div id="carousel" className="d-block d-lg-none col-12 col-lg-5 carousel slide align-self-center row flex-fill position-relative" data-bs-ride="carousel" data-bs-wrap="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner py-5">
                            {
                                testimonials.map((testimonial, index) =>
                                    <div key={testimonial.clientName} className={`carousel-item  col-12 col-lg-6 mt-5 ${index == 0 ? "active" : ""}`}>
                                        <TestmonialCard clientName={testimonial.clientName} clientComment={testimonial.clientComment} clientLocation={testimonial.clientLocation} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ContainerFluid>
        </div>
    )
}


export default MVPSection;