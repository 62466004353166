import { BranchIcon, CallCenterIcon, MailIcon } from "../../Assets/Icons";
import { BlogImg } from "../../Assets/Images";
import AppsSection from "../Components/AppsSection";
import BlogCard from "../Components/BlogCard";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import "./style.scss"

declare interface ContactCardProps {
    cardImg: string;
    cardTitle: string;
    cardDetail: string | any;
}

const ContactCard: React.FC<ContactCardProps> = (props) => {
    return (
        <div className="contact-card position-relative rounded-4 shadow p-3 bg-teal">
            <div className="card-img relative mb-5 pb-3 d-flex justify-content-center">
                <img src={props.cardImg} className="img" style={{ width: 120, height: 120 }} />
            </div>
            <div className="card-title text-center mt-2">
                <h5 className="m-0 p-0 fw-bolder">{props.cardTitle}</h5>
            </div>
            <div className="card-detail mt-4 text-center" >
                <p className="fs-6 fw-bold" style={{ color: "#6A4242" }}>
                    {props.cardDetail}
                </p>
            </div>
        </div>
    )
}

const ContactLanding = () => {
    return (
        <div className="contact-landing px-lg-4 px-4 mt-5" style={{ marginTop: "8rem" }}>
            <div className="row py-4 py-lg-6">
                <div className="col-12  align-self-center text-center">
                    <h2 className="fw-bolder">
                        Get In Touch
                    </h2>
                    <p className="detail fs-5">
                        We would love to hear from you! Our team is here to help. See our points of contact below.
                    </p>
                </div>
                <div className="col-12 mt-4">
                    <div className="row">
                        <div className="col-12 col-lg-4" style={{ marginTop: "4rem" }}>

                            <ContactCard cardImg={CallCenterIcon} cardTitle={"Give us a call"} cardDetail={<div>Let’s talk + 234 909 051 2482, <br /> +234 819 017 8697</div>} />
                        </div>
                        <div className="col-12 col-lg-4" style={{ marginTop: "4rem" }}>
                            <ContactCard cardImg={MailIcon} cardTitle={"send a mail"} cardDetail={"Send mails to support@quickfund.com"} />
                        </div>
                        <div className="col-12 col-lg-4" style={{ marginTop: "4rem" }}>
                            <ContactCard cardImg={BranchIcon} cardTitle={"Visit a branch"} cardDetail={"You can visit our office at 20 Channels Drive, OPIC Estate, Isheri, Ogun State"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


// TODO: Getting blog post for blog insights
const BlogPostInsights = () => {
    return (
        <div className="d-none d-lg-block blog-post-insights bg-black p-2  mt-5">
            <div className="text-white px-lg-5 px-0 pt-5">
                <h3 className="font-primary fw-bolder">From Blog</h3>
                <h6>
                    Explore our blog for insightful articles, personal reflections, impactful resources,
                    and ideas that inspire us at QuickFund
                </h6>
            </div>

            <div className="d-flex justify-content-around mt-5 flex-column flex-lg-row">
                <BlogCard
                    className="mt-2"
                    darkMode={true}
                    blogDate="January 25, 2022"
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
                <BlogCard
                    className="mt-2"
                    blogDate="January 25, 2022"
                    darkMode={true}
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
                <BlogCard
                    className="mt-2"
                    blogDate="January 25, 2022"
                    darkMode={true}
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
            </div>
            <div className="px-5 mt-3">
                <a href="#" className="text-white ">View more...</a>
            </div>
        </div>
    )
}

const Contact = () => {

    return (
        <div className="contact">
            <div className="py-md-0 pt-5">
                <ContactLanding />
            </div>
            <SocialMediaSection />
            <BlogPostInsights />
            <NewsLetterSection />
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    );
}

export default Contact;