import { HTMLAttributes } from "react";
import "./style.scss"


declare interface BlogCardProps extends HTMLAttributes<HTMLDivElement> {
    blogImg: string;
    blogDate: string;
    blogPost: string;
    darkMode: boolean
}

const BlogCard: React.FC<BlogCardProps> = (props) => {
    const maxWordCount = 70;

    return (
        <div className={`blog-card ${props.className}`} >
            <div>
                <img src={props.blogImg} alt="" className="img img-fluid" />
            </div>
            <div className="mt-2">
                <h6 className={`blog-card-date ${props.darkMode ? "text-light" : "text-secondary"}`}>{props.blogDate}</h6>
                <h6 className={`${props.darkMode ? "text-light" : "text-black"}`}>{props.blogPost.substring(0, maxWordCount)}
                    <a className="nav-link d-inline"> <span className="text-red mr-1">Read more</span>
                    </a>
                </h6>
            </div>
        </div>
    )
}


export default BlogCard;