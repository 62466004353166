import { useState } from "react";
import { Link } from "react-router-dom";
import AppsSection from "../Components/AppsSection";
import BenefitSection from "../Components/BenefitSection";
import DiscoverSection from "../Components/Discover";
import Footer from "../Components/Footer";
import HomeLanding from "../Components/HomeLanding";
import MVPSection from "../Components/MVPSection";
import NoteSection from "../Components/NoteSection";
import { CollapseCard } from "../FAQ";
import "./style.scss";

const faqs = [
	{
		question: 'Who is QuickFund Bank?',
		answer: " QuickFund is a Microfinance bank licenced by CBN. We grants Loans, Savings, deposit, withdrawals and any other banking related activities."
	},
	{
		question: 'Is QuickFund a registered and licensed bank?',
		answer: "Yes, we are a microfinance banking license from the Central Bank of Nigeria. Our registration number is RC 1460935"
	},
	{
		question: "How do I open an account with QuickFund?",
		answer: "You can create an account via our app or walk in into our bank.",
	},
	{
		question: 'Is my money safe with QuickFund?',
		answer: "Yes, your money is safe with us.We secure all accounts with the same high- security encryption used by regular banks, we also insure every deposit you make into account."
	},

	{
		question: 'Can I have more than one account with the bank?',
		answer: "Yes, there's no limit to the number of accounts you can have."
	},

]

const FAQSection = () => {
	const [activeTab, setActiveTab] = useState("general");

	return (
		<>
			<div className="faq-section py-5 d-none d-lg-block px-5">
				<div className="text-left text-start pb-5 px-5">
					<h2 className="font-primary fw-bolder px-5">Frequently Asked Questions</h2>
					<div className="d-flex flex-row  mt-4 px-5">
						<div onClick={() => setActiveTab("general")} className={`fs-6 me-5 ${activeTab == "general" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>
							<div>
								General
							</div>
							{activeTab == "general" && <div className="">
								<svg width="15" height="10" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g filter="url(#filter0_d_4815_485)">
										<path d="M12.8296 0.479606C12.6707 0.248368 12.3293 0.248369 12.1704 0.479607L2.10534 15.1234C1.92292 15.3888 2.11293 15.75 2.43498 15.75L22.565 15.75C22.8871 15.75 23.0771 15.3888 22.8947 15.1234L12.8296 0.479606Z" fill="#520101" />
									</g>
									<defs>
										<filter id="filter0_d_4815_485" x="0.0341797" y="0.306641" width="24.9316" height="19.4434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
											<feFlood flood-opacity="0" result="BackgroundImageFix" />
											<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
											<feOffset dy="2" />
											<feGaussianBlur stdDeviation="1" />
											<feComposite in2="hardAlpha" operator="out" />
											<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
											<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_485" />
											<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4815_485" result="shape" />
										</filter>
									</defs>
								</svg>
							</div>}
						</div>
						<div onClick={() => setActiveTab("instant")} className={`fs-6 me-5 ${activeTab == "instant" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>
							Instant Loan
							{activeTab != "general" && <div className="">
								<svg width="15" height="10" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g filter="url(#filter0_d_4815_485)">
										<path d="M12.8296 0.479606C12.6707 0.248368 12.3293 0.248369 12.1704 0.479607L2.10534 15.1234C1.92292 15.3888 2.11293 15.75 2.43498 15.75L22.565 15.75C22.8871 15.75 23.0771 15.3888 22.8947 15.1234L12.8296 0.479606Z" fill="#520101" />
									</g>
									<defs>
										<filter id="filter0_d_4815_485" x="0.0341797" y="0.306641" width="24.9316" height="19.4434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
											<feFlood flood-opacity="0" result="BackgroundImageFix" />
											<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
											<feOffset dy="2" />
											<feGaussianBlur stdDeviation="1" />
											<feComposite in2="hardAlpha" operator="out" />
											<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
											<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_485" />
											<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4815_485" result="shape" />
										</filter>
									</defs>
								</svg>
							</div>}
						</div>
						<div>
							<Link to={"/faq"} style={{ color: "#0442A3", fontSize: "0.8rem" }} className="btn  border rounded-pill">See All</Link>
						</div>
					</div>
				</div>

				<div className="mt-2 px-5">
					<div className="px-5">
						{
							activeTab == "general" ? faqs.map((faq, index) => <CollapseCard
								className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
								title={faq.question}
								response={faq.answer}
								faqId={"faq" + index}
							/>) : <>
								<CollapseCard
									className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
									title={"How can I apply for a QuickFund instant loan?"}
									response={
										<div>
											<ul>
												<li>
													Trough the Unique lenders page.
													Click https://www.quickteller.com/lender/quickfund to apply now
												</li>
												<ul>
													<li> Login to the portal</li>
													<li>   Click the request loan now button.</li>
													<li> Click the preferred amount button.</li>
													<li>Click and accept the available offer button.</li>
												</ul>
												<li>
													Trough the Quickteller portal
												</li>
												<ul>
													<li> Go to the QuickTeller website. https://www.quickteller.com</li>
													<li> Login to the QuickTeller Portal</li>
													<li> Search for the loan section and click the button to access the loan page</li>
													<li> Click the preferred amount</li>
													<li> Click the available offer.</li>
													<li> Input your card details</li>
													<li> Accept the available amount</li>
												</ul>
												<li>
													Trough the UBA mobile app
												</li>
												<ul>
													<li> Search for the loan page on the UBA mobile app and click apply to see eligible offers
													</li>
												</ul>
												<li>
													Through the QuickTeller USSD channel
												</li>
												<ul>
													<li> Click the link *723*6# and follow the prompt
													</li>
												</ul>
											</ul>
										</div>
									}

									faqId={"faq1"}
								/>
								<CollapseCard
									className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
									title={"How do I know if I am eligible to request a loan?"}
									response={
										<div>
											<ul>
												<li>
													You may apply for our loan on any instant loan platform to confirm your eligibility.
												</li>
											</ul>
										</div>
									}

									faqId={"faq2"}
								/>
								<CollapseCard
									className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
									title={"How can repay my loan?"}
									response={
										<div>
											<ul>
												<li>
													Through your unique virtual account.
												</li>
												<ul>
													<li>
														A unique virtual account is a temporary account we assign to each customer to ease repayment, see below for more details.
														<div className="mt-1">
															To repay your loan via your unique virtual account, simply transfer the due loan amount to the virtual account and we do the rest.
														</div>
													</li>
												</ul>
												<li>
													Trough the Quickteller payment portal
												</li>
												<ul>
													<li> The customer login to the QuickTeller portal.</li>
													<li> The customer searches for the loan section and clicks the loan button.</li>
													<li> The customer clicks the repay loan button to pay the loan.</li>
												</ul>
												<li>
													Through the QuickFund unique lenders link
												</li>
												<ul>
													<li> The customer login through the unique lenders link +</li>
													<li> The customer clicks the repay button to pay the loan.</li>
												</ul>
												<li>
													Through the channel the customer got the loan from e.g. UBA
												</li>
												<ul>
													<li> The customer login to the channel that the loan was disbursed</li>
													<li> The customer goes to the loan section and clicks repayment.</li>
												</ul>
											</ul>
										</div>
									}

									faqId={"faq3"}
								/>
								<CollapseCard
									className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
									title={"What is a virtual account?"}
									response={
										<div>
											<ul>
												<li>
													A virtual account is a temporary, fast and easy medium of payment. It is created when a customer applies for a loan and it reduces the possibility that the customer experiences issues with verification or reversal while repaying their loan.
													<span className="fw-bold">  NOTE</span> - Your virtual account will always carry your name, always check before transferring that the account name is yours.
												</li>

											</ul>
										</div>
									}

									faqId={"faq4"}
								/>
								<CollapseCard
									className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
									title={"How can I increase my offer amount?"}
									response={
										<div>
											<ul>
												<li>
													By maintaining a good credit – Your behaviour with loans is tracked across lending companies and banks, for this reason defaulting with one lender can affect your chances of getting loans from other lenders.
												</li>
												<li>
													By not defaulting when you take a loan on our channel – Timely repayment by QuickFund customers means they can access even higher offers and maintain a good credit score.
												</li>
												<li>
													By not defaulting a loan with other lenders – For customers who switch to QuickFund, good repayment behaviour with other lenders will improve your credit rating and improve the loan offers you get from us.
												</li>

											</ul>
										</div>
									}

									faqId={"faq5"}
								/>

							</>
						}
					</div>
				</div>
			</div>

			{/* Mobile */}
			<div className="faq-section py-5 d-block d-lg-none">
				<div className="text-center">
					<h1 className="text-dark-teal display-5 px-4 font-primary fw-bolder">Frequently Asked Questions</h1>
					<div className="d-flex flex-row justify-content-center mt-4">
						<div onClick={() => setActiveTab("general")} className={`fs-6 me-5 ${activeTab == "general" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>General</div>
						<div onClick={() => setActiveTab("instant")} className={`fs-6 me-5 ${activeTab == "instant" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>Instant Loan</div>
					</div>
				</div>

				<div className="mt-5 px-1">
					<div className="px-2">

						{
							activeTab == "general" ? faqs.map((faq, index) => <CollapseCard
								className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
								title={faq.question}
								response={faq.answer}
								faqId={"faq" + index}
							/>) : <>
								<CollapseCard
									className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
									title={"How can I apply for a QuickFund instant loan?"}
									response={
										<div>
											<ul>
												<li>
													Trough the Unique lenders page.
													Click https://www.quickteller.com/lender/quickfund to apply now
												</li>
												<ul>
													<li> Login to the portal</li>
													<li>   Click the request loan now button.</li>
													<li> Click the preferred amount button.</li>
													<li>Click and accept the available offer button.</li>
												</ul>
												<li>
													Trough the Quickteller portal
												</li>
												<ul>
													<li> Go to the QuickTeller website. https://www.quickteller.com</li>
													<li> Login to the QuickTeller Portal</li>
													<li> Search for the loan section and click the button to access the loan page</li>
													<li> Click the preferred amount</li>
													<li> Click the available offer.</li>
													<li> Input your card details</li>
													<li> Accept the available amount</li>
												</ul>
												<li>
													Trough the UBA mobile app
												</li>
												<ul>
													<li> Search for the loan page on the UBA mobile app and click apply to see eligible offers
													</li>
												</ul>
												<li>
													Through the QuickTeller USSD channel
												</li>
												<ul>
													<li> Click the link *723*6# and follow the prompt
													</li>
												</ul>
											</ul>
										</div>
									}

									faqId={"faq1"}
								/>
								<CollapseCard
									className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
									title={"How do I know if I am eligible to request a loan?"}
									response={
										<div>
											<ul>
												<li>
													You may apply for our loan on any instant loan platform to confirm your eligibility.
												</li>
											</ul>
										</div>
									}

									faqId={"faq2"}
								/>
								<CollapseCard
									className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
									title={"How can repay my loan?"}
									response={
										<div>
											<ul>
												<li>
													Through your unique virtual account.
												</li>
												<ul>
													<li>
														A unique virtual account is a temporary account we assign to each customer to ease repayment, see below for more details.
														<div className="mt-1">
															To repay your loan via your unique virtual account, simply transfer the due loan amount to the virtual account and we do the rest.
														</div>
													</li>
												</ul>
												<li>
													Trough the Quickteller payment portal
												</li>
												<ul>
													<li> The customer login to the QuickTeller portal.</li>
													<li> The customer searches for the loan section and clicks the loan button.</li>
													<li> The customer clicks the repay loan button to pay the loan.</li>
												</ul>
												<li>
													Through the QuickFund unique lenders link
												</li>
												<ul>
													<li> The customer login through the unique lenders link +</li>
													<li> The customer clicks the repay button to pay the loan.</li>
												</ul>
												<li>
													Through the channel the customer got the loan from e.g. UBA
												</li>
												<ul>
													<li> The customer login to the channel that the loan was disbursed</li>
													<li> The customer goes to the loan section and clicks repayment.</li>
												</ul>
											</ul>
										</div>
									}

									faqId={"faq3"}
								/>
								<CollapseCard
									className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
									title={"What is a virtual account?"}
									response={
										<div>
											<ul>
												<li>
													A virtual account is a temporary, fast and easy medium of payment. It is created when a customer applies for a loan and it reduces the possibility that the customer experiences issues with verification or reversal while repaying their loan.
													<span className="fw-bold">  NOTE</span> - Your virtual account will always carry your name, always check before transferring that the account name is yours.
												</li>

											</ul>
										</div>
									}

									faqId={"faq4"}
								/>
								<CollapseCard
									className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
									title={"How can I increase my offer amount?"}
									response={
										<div>
											<ul>
												<li>
													By maintaining a good credit – Your behaviour with loans is tracked across lending companies and banks, for this reason defaulting with one lender can affect your chances of getting loans from other lenders.
												</li>
												<li>
													By not defaulting when you take a loan on our channel – Timely repayment by QuickFund customers means they can access even higher offers and maintain a good credit score.
												</li>
												<li>
													By not defaulting a loan with other lenders – For customers who switch to QuickFund, good repayment behaviour with other lenders will improve your credit rating and improve the loan offers you get from us.
												</li>

											</ul>
										</div>
									}

									faqId={"faq5"}
								/>

							</>
						}
					</div>
				</div>
			</div>
		</>
	)
}


const Home: React.FC = () => {
	return (
		<div className="home">
			<HomeLanding />

			{/* desktop */}
			<div className="p-5 d-none d-lg-block">
				<div className="px-5 text-end">
					<svg width="70" height="70" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect y="26.0449" width="57.2254" height="57.2254" rx="5" transform="rotate(-27.0735 0 26.0449)" fill="#FEE4E7" />
						<circle cx="38.8945" cy="37.5942" r="13.5678" fill="white" />
					</svg>
				</div>

			</div>
			<DiscoverSection />
			<div className="py-lg-5 py-4 mt-lg-4"></div>
			<BenefitSection />
			<MVPSection />
			<FAQSection />
			<div className="d-block d-lg-none">
				<AppsSection />
			</div>
			<NoteSection />
			<Footer />
		</div>
	)
}

export default Home;