import { NoteSectionBg } from "../../../Assets/Icons";
import { Container } from "../../../Components/Container";
import NoteCard from "../../../Components/NoteCard"

const notes = [
    {
        title: "QuickFund for Individuals and Entrepreneurs",
        description: "QuickFund offers convenient banking services for individuals and business purposes. QuickFund is the right choice for you whether you need a loan to buy a car, pay school fees, home improvement or other life needs, or you need new equipment, a new space or other things to make your business flourish."
    },
    {
        title: "QuickFund for MSMEs",
        description: "Our system is built around helping your business grow. We offer lots of benefits for MSMEs, including increased accessibility to funds for small and medium scale enterprises. Also, our online and offline banking systems run without glitches for the smooth running of your business transactions."
    },
    {
        title: "QuickFund for Salary Earners and Civil Servant",
        description: "This package is designed to provide convenient and seamless access to funds for employees under paid employment with reputable companies and civil servants. You work hard all month then you deserve to enjoy your pay, even in advance, in addition to the fact that you are guaranteed access to services that will further enhance your banking experience."
    },

]

const NoteSection = () => {
    return (
        <div style={{ backgroundImage: `url(${NoteSectionBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "right" }}>
            <div className="py-1 py-lg-5 mt-1 mt-lg-5">
                <Container style={{}}>
                    <div className="note-section mt-1 mt-lg-5 py-1 py-lg-5">
                        <div className="row justify-content-around">
                            {
                                notes.map((note) => <div key={note.title} className="col-lg-4 mt-3">
                                    <div className="mx-auto h-100" style={{ width: "fit-content" }}>
                                        <NoteCard title={note.title} description={note.description} />
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
}

export default NoteSection;