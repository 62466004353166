import "./style.scss"
import { AppleIcon, GooglePlayIcon, StarIcon } from "../../../Assets/Icons"
import { BgTeal, HomeCollectionImg } from "../../../Assets/Images"
import AppStoreButton from "../StoreButtons/AppStoreButton";
import GooglePlayButton from "../StoreButtons/GooglePlayButton";
import USSDButton from "../StoreButtons/USSDButton";

const HomeLanding = () => {
    return (
        <div className="home-landing container-fluid px-lg-5">
            <div className="px-lg-5 py-lg-6">
                <div className="row py-5 justify-content-between">
                    <div className="col-lg-5 py-3 mb-2">
                        <div className="px-1 left">
                            {/* desktop */}
                            <div className="d-none d-lg-block">
                                <div className="position-relative">
                                    <div className="position-absolute" style={{ bottom: 10, left: -10 }}>
                                        <svg width="128" height="14" viewBox="0 0 128 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="128" height="14" fill="#F6011B" fill-opacity="0.29" />
                                        </svg>
                                    </div>
                                    <h1 className="display-5 fw-bold d-none d-lg-block"> MODERN BANK FOR  </h1>
                                </div>
                                <h1 className="display-5 fw-bold d-none d-lg-block"> DIGITAL AGE </h1>
                            </div>

                            {/* mobile */}
                            <div className="d-block d-lg-none position-relative">
                                <svg width="128" height="14" viewBox="0 0 128 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="128" height="14" fill="#F6011B" fill-opacity="0.29" />
                                </svg>

                                <h1 className="display-5 fw-bold text-center"> MODERN BANK FOR DIGITAL AGE </h1>
                            </div>


                            {/* desktop  */}
                            <div className="d-none d-lg-block">
                                <div className="position-relative d-inline-block">
                                    <div className="relative">

                                        <h3 className="fw-normal mt-2">ONE APP, SEVERAL POSSIBILTIES.</h3>
                                    </div>
                                    <div className="position-absolute end-0">
                                        <svg width="252" height="17" viewBox="0 0 252 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="130" width="122" height="7" fill="#F6011B" fill-opacity="0.29" />
                                            <rect y="10" width="252" height="7" fill="#F6011B" fill-opacity="0.29" />
                                        </svg>

                                    </div>
                                </div>
                                <h4 className="mt-5 fs-5 lh-base"> Join other customers and enjoy 24/7 <br />
                                    access to seamless banking and credit facilities.</h4>
                                <span className="charges"> NO HIDDEN CHARGES!</span>
                            </div>

                            {/* mobile */}
                            <div className="d-block d-lg-none text-center">
                                <div className="">
                                    <h6 className="fw-normal mt-3">ONE APP, SEVERAL POSSIBILTIES.</h6>
                                    <div className="">
                                        <svg width="149" height="14" viewBox="0 0 149 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="38.2354" width="71.7647" height="4.11765" fill="#F6011B" fill-opacity="0.29" />
                                            <rect y="9.11765" width="148.235" height="4.11765" fill="#F6011B" fill-opacity="0.29" />
                                        </svg>
                                    </div>
                                </div>
                                <h5 className="mt-3 lh-base fw-normal fs-6">
                                    Join other customers  <br /> and enjoy 24/7
                                    access to seamless banking and credit facilities.
                                </h5>
                                <span className="charges small"> NO HIDDEN CHARGES!</span>
                            </div>

                            <div className="mt-4 d-none d-lg-block">
                                <h4 className="font-primary fw-bolder fs-5 mb-3"> Get the App </h4>
                                <AppStoreButton />
                                <GooglePlayButton />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="position-relative">
                            <div className="position-absolute border-0 img bg-ovall ">
                                <svg className="w-100" width="708" height="722" viewBox="0 0 708 722" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M384.672 3.70534C454.234 10.1144 510.099 49.1777 566.124 85.8207C615.525 118.132 664.918 151.911 688.781 202.608C712.388 252.761 712.606 309.66 696.6 363.015C681.161 414.478 631.705 448.302 601.261 494.339C552.048 568.757 553.484 684.745 463.607 715.338C377.971 744.488 298.527 663.981 220.423 623.973C142.986 584.308 45.4262 558.134 11.6928 483.855C-21.8368 410.025 26.3389 328.212 50.7273 250.315C73.4415 177.766 83.1817 95.4644 148.363 47.3299C212.498 -0.0324698 303.26 -3.79566 384.672 3.70534Z" fill="#FCB5BD" fill-opacity="0.37" />
                                </svg>

                                {/* <img className="w-100" src={BgTeal} /> */}
                            </div>
                            <img className="border-0 img img-thumbnail bg-transparent" src={HomeCollectionImg} />
                        </div>
                    </div>

                    {/* mobile */}
                    <div className="mt-5 text-center d-block d-lg-none">
                        <h4 className="px-2 font-primary lh-base fw-bolder text-dark-teal mb-0 mt-0"> Discover why we are the best bank for you </h4>
                        <div className="rating">
                            <img src={StarIcon} width={20} />
                            <img src={StarIcon} width={20} />
                            <img src={StarIcon} width={20} />
                            <img src={StarIcon} width={20} />
                            <img src={StarIcon} width={20} />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="mt-2">
                                <AppStoreButton />
                            </div>
                            <div className="mt-3">
                                <GooglePlayButton />
                            </div>
                            <div className="mt-3">
                                <USSDButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}


export default HomeLanding;