import { AppleIcon, CBNLogo, GooglePlayIcon } from "../../../Assets/Icons";
import AppStoreButton from "../StoreButtons/AppStoreButton";
import GooglePlayButton from "../StoreButtons/GooglePlayButton";

const AppsSection = () => {
    return (
        <>
            <div className="app-section py-5 bg-light text-center d-none d-lg-block">
                <div>
                    <h3 className="font-primary fw-bolder">Begin your journey to financial freedom</h3>
                    <h6>Fully Licensed by the CBN</h6>
                    <div className="mt-3">
                        <AppStoreButton className="mt-2" />
                        <GooglePlayButton className="mt-2" />
                    </div>
                </div>
            </div>


            <div className="app-section px-4 py-5 text-center d-block d-lg-none">
                <div>
                    <h1 className="font-primary text-dark-teal fw-bolder">Open an account for free</h1>
                    <h6 className="lh-base mt-2 small">Fully licensed by the CBN. <img width={24} src={CBNLogo} alt="" /></h6>
                    <div className="mt-3">
                        <AppStoreButton className="mt-2" />
                        <GooglePlayButton className="mt-2" />
                    </div>
                </div>
            </div>
        </>

    )
}

export default AppsSection;