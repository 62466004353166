import { useState } from "react"
import { Link } from "react-router-dom"
import { QuickFundIcon } from "../../../Assets/Icons"
import AppsSection from "../AppsSection"
import TrustieSection from "../TrustieSection"
import "./style.scss"

const OpenAccountMobile = () => {
    const [isClicked, setIsClicked] = useState(false);

    return (
        <div className="mobile-action bg-teal fixed-bottom d-lg-none d-block px-3 pb-4 py-3">
            <div className="d-flex">
                <div className="me-2">
                    <span className="lh-0 fw-bolder text-black" style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}>
                        Get instant loan in less than 5 minutes
                    </span>

                </div>
                <div className="col-6 align-self-center d-flex justify-content-center">
                    <a href="https://www.quickteller.com/lender/quickfund" className="d-inline-block bg-white btn  fw-bolder text-black border-black py-3" style={{ width: "100%", fontFamily: "Poppins", fontSize: "0.8rem", border: "2px solid black", borderRadius: "0.8rem" }}>
                        GET INSTANT LOAN
                    </a>
                </div>
            </div>
        </div>
    )
}

const Footer = () => {
    return (
        <>
            <OpenAccountMobile />
            <footer className="mt-4 ">
                <TrustieSection />
                <div className="d-none d-md-block">
                    <AppsSection />
                </div>

                <div className="py-5 px-3">
                    <div className="row mt-4 justify-content-evenly">
                        <div className="col-lg-4 col-12 mt-3">
                            <div className="mb-4">
                                <div><img src={QuickFundIcon} width={130} alt="" className="img" /></div>
                            </div>
                            <div>
                                <p>
                                    20 Channels Drive, OPIC Estate, Isheri, Ogun State.
                                </p>
                                <p>
                                    support@quickfund.com <br></br>
                                    <div className="fw-bold mt-1">
                                        +234 909 051 2482 <br /> +234 819 017 8697
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 mt-3">
                            <h6 className="fw-bolder">COMPANY</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item"><Link to="/about" className="nav-link px-0 text-dark fw-light"> About Us</Link> </li>
                                <li className="nav-item"><Link to="/contact" className="nav-link px-0 text-dark fw-light"> Contact us</Link> </li>
                                <li className="nav-item"><Link to="/articles" className="nav-link px-0 text-dark fw-light"> Blog</Link> </li>
                                <li className="nav-item"><Link to="/faq" className="nav-link px-0 text-dark fw-light"> FAQs</Link> </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-6 mt-3">
                            <h6 className="fw-bolder">PRODUCTS</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item"><Link to="/savings-and-deposit" className="nav-link px-0 text-dark fw-light">Savings and Deposits</Link> </li>
                                <li className="nav-item"><Link to="/loan-and-overdraft" className="nav-link px-0 text-dark fw-light"> Loans</Link> </li>
                                <li className="nav-item"><Link to="#" className="nav-link px-0 text-dark fw-light"> Offline Banking</Link> </li>
                                <li className="nav-item"><Link to="#" className="nav-link px-0 text-dark fw-light"> Internet Banking</Link> </li>
                                <li className="nav-item"><a href="https://www.quickteller.com/lender/quickfund" className="nav-link px-0 text-dark fw-light"> Instant Loan</a> </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-12 mt-3">
                            <h6 className="fw-bolder">OTHERS</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item"><Link to="/privacy-policy" className="nav-link px-0 text-dark fw-light">Privacy Policy</Link> </li>
                                <li className="nav-item"><Link to="/terms-and-condition" className="nav-link px-0 text-dark fw-light"> Terms and Conditions</Link> </li>

                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-lg-row flex-column px-5">
                        <div className="px-1 my-4 align-self-center">
                            <a target="blank" href="https://www.facebook.com/quickfundng/" className="fs-5 text-black me-4"><i className="bi bi-facebook"></i></a>
                            <a target="blank" href="https://instagram.com/quickfundmfb?igshid=YmMyMTA2M2Y=" className="fs-5 text-black me-4"><i className="bi bi-instagram"></i></a>
                            <a target="blank" href="https://twitter.com/quickfundmfb1?t=KvKU4_mb0MbO7N7dIFpv0g&s=09" className="text-black me-4"><i className="bi bi-twitter"></i></a>
                            <a target="blank" href="#" className="text-black me-4"><i className="fs-5 bi bi-youtube"></i></a>
                            <a target="blank" href="#" className="text-black me-4"><i className="fs-5 bi bi-medium"></i></a>
                            <a target="blank" href="#" className=" text-black me-4"><i className="fs-5 bi bi-linkedin"></i></a>
                        </div >
                        <div className="align-self-center">
                            <p className="text-red text-center">
                                {new Date().getFullYear()} All Rights Reserved.
                            </p>
                        </div>
                    </div >
                </div >
            </footer >
        </>
    )
}

export default Footer;