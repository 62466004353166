import { IphoneMock } from "../../../Assets/Images";
import { Container } from "../../../Components/Container";
import AppStoreButton from "../StoreButtons/AppStoreButton";
import GooglePlayButton from "../StoreButtons/GooglePlayButton";

const OpenAccountSection = () => {
    return (
        <div className="px-lg-5 px-1 d-none d-lg-block">
            <div className="open-account-section px-3 mt-5">
                <div style={{ backgroundColor: "#F6011B" }} className="d-flex  pb-3 pb-lg-0  pt-5 px-3 px-lg-4 justify-content-evenly rounded-4 flex-column flex-lg-row">
                    <div className="position-relative d-flex justify-content-center">
                        <div className="position-absolute" style={{ left: -81 }}>
                            <svg width="259" height="251" viewBox="0 0 259 251" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M255.96 125.106L2.99998 125.688" stroke="#282828" strokeOpacity="0.25" strokeWidth="6" strokeLinecap="round" />
                                <path d="M220.825 145.521L37.7612 147.463" stroke="#282828" strokeOpacity="0.25" strokeWidth="6" strokeLinecap="round" />
                                <path d="M187.665 168.998L72.7085 172.058" stroke="#282828" strokeOpacity="0.25" strokeWidth="6" strokeLinecap="round" />
                                <path d="M162.504 190.163L104.508 191.907" stroke="#F9ACA9" strokeOpacity="0.25" strokeWidth="6" strokeLinecap="round" />
                                <circle cx="120.775" cy="74.5986" r="9.5" transform="rotate(41.1819 120.775 74.5986)" fill="#F9ACA9" fillOpacity="0.25" />
                            </svg>

                        </div>
                        <img src={IphoneMock} alt="" className="img-fluid" style={{ width: "70%", zIndex: 2 }} />
                    </div>
                    <div className="text-white mt-4 position-relative">
                        <h2 className="text-white mb-2 font-secondary lh-base">
                            Open an account <br /> for free
                        </h2>
                        <h6>
                            Download the QuickFund app for free </h6>
                        <h6>today and enjoy seamless banking and
                            credit facilities.
                        </h6>
                        <div className="mt-4">
                            <AppStoreButton className={"me-3 mt-2"} />
                            <GooglePlayButton className="mt-2" />
                        </div>
                        <div className="position-absolute" style={{ top: -54, right: -50 }}>
                            <svg width="166" height="173" viewBox="0 0 196 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M193 3L3 170" stroke="#282828" strokeOpacity="0.5" strokeWidth="6" strokeLinecap="round" />
                                <path d="M180 41.5L43.5 163.5" stroke="#282828" strokeOpacity="0.5" strokeWidth="6" strokeLinecap="round" />
                                <path d="M170.5 81L86 159" stroke="#282828" strokeOpacity="0.5" strokeWidth="6" strokeLinecap="round" />
                                <path d="M165.5 113.5L123 153" stroke="#F9ACA9" strokeOpacity="0.5" strokeWidth="6" strokeLinecap="round" />
                                <circle cx="58" cy="54" r="9.5" fill="#F9ACA9" fillOpacity="0.5" />
                            </svg>


                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}


export default OpenAccountSection;