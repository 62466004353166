import { useState } from "react";
import { IphoneMock, OracleLandingImg } from "../../Assets/Images";
import FileDrapDrop from "../../Components/FileDragDrop";
import Footer from "../Components/Footer";
import HowItWorksSection from "../Components/HowItWorksSection";
import OpenAccountSection from "../Components/OpenAccountSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
import "./style.scss"


const OracleLanding = () => {
    return (
        <div className="bills-and-more-landing px-lg-4 px-1 px-lg-4 mt-5">
            <div className="row py-4">
                <div className="col-12 col-lg-6 align-self-center mb-4">
                    <h2 className="font-primary fw-bolder">
                        PUBLIC SECTOR LOAN (ORACLE)
                    </h2>
                    <div className="mt-4">
                        <div className="ps-4">
                            <h4 className="text-dark-teal font-primary fw-bolder">
                                <span className="success d-inline-flex me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-square-fill" viewBox="0 0 16 16">
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                    </svg>
                                </span>
                                Eligibility
                            </h4>
                            <div className="ps-3">
                                <p>
                                    Wе are the bank for the Millenials, Gen Z and Baby Boomers. We are hеrе to рrоvіdе innovative fіnаnсіаl ѕоlutіоnѕ that meets the financial needs of our customers. Our goal is to make banking accessible to everyone and to provide different lending options by leveraging technology and we also make gеttіng loans аn еаѕу and ѕtrеѕѕ-frее рrосеѕѕ!

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="ps-4">
                            <h4 className="text-dark-teal font-primary fw-bolder">
                                <span className="success d-inline-flex me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-square-fill" viewBox="0 0 16 16">
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
                                    </svg>
                                </span>
                                Requirement to apply
                            </h4>
                            <div className="ps-3">
                                <p>

                                    Wе are the bank for the Millenials, Gen Z and Baby Boomers.
                                    We are hеrе to рrоvіdе innovative fіnаnсіаl ѕоlutіоnѕ that meets
                                    the financial needs of our customers. Our goal is to make banking
                                    accessible to everyone and to provide different lending options by
                                    leveraging technology and we also make gеttіng loans аn еаѕу and
                                    ѕtrеѕѕ-frее рrосеѕѕ!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ps-4 mt-5">
                        <span className="bg-gray-200 text-dark-teal px-3 py-2 font-primary fw-bolder rounded-3">
                            09090512482
                        </span>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mt-4">
                    <img src={OracleLandingImg} alt="" className="img img-fluid" />
                </div>
            </div>
        </div >
    )
}

//TODO: move to new

const PersonalDetailsForm = () => {
    return (
        <>
            <div className="px-1 px-lg-4">
                <p>
                    <span className="text-dark-teal fw-bold">Hi</span> there, please fill in your correct bio data.
                </p>
                <p>
                    <span className="text-dark-teal fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
                </p>
            </div>
            <div className="p-1 p-lg-5">
                <div className="">
                    <form action="">
                        <div className="row">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="surname">Surname <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="surname" name="surname" />
                            </div>
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="surname">Surname <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="surname" name="surname" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="gender">Gender <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="gender" name="gender" />
                            </div>
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="email">Email <span className="text-red">*</span></label>
                                <input type="email" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="email" name="email" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="phone">Phone Number</label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="phone" name="phone" />
                            </div>
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="dob">Data <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="dob" name="dob" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-12 px-0 px-lg-4">
                                <label htmlFor="address">Home Address <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="state" name="state" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="lga">LGA <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="lga" name="lga" />
                            </div>
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="state">State <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="state" name="state" />
                            </div>
                        </div>

                        <div className="px-0 px-lg-4 mt-5">
                            <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

const LoanAmountForm = () => {
    return (
        <>
            <div className="px-1 px-lg-4 font-primary">
                <h5 className="font-primary">
                    <span className="text-dark-teal fw-bold">How much</span> do you need? Access up to NGN1,000,000
                </h5>
                <p>
                    <span className="text-dark-teal fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
                </p>
            </div>
            <div className="p-1 p-lg-5">
                <div className="">
                    <form action="">
                        <div className="row">
                            <div className="col-md-7 px-0 px-lg-4">
                                <label htmlFor="amount">Amount (N20,000 - N1,000,000) <span className="text-red">*</span></label>
                                <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="amount" name="amount" defaultValue={"150,000"} />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-5 px-0 px-lg-4">
                                <label htmlFor="tenure">Tenure ( 3-12 Months) <span className="text-red">*</span></label>
                                <select className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" id="tenure" name="tenure">
                                </select>
                            </div>
                        </div>

                        <div className="row mt-5 px-0 px-lg-4">
                            <div className="col-md-8 p-1 p-lg-5 border border-dark rounded-3">
                                <span>Proposed Loan Schedule</span>
                                <input disabled type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="phone" name="" defaultValue={"Monthly Repayment: N20,500"} />
                                <input disabled type="text" className="mt-4 form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="phone" name="" defaultValue={"Tenure: 6 Months"} />
                            </div>
                        </div>


                        <div className="px-0 px-lg-4 mt-5">
                            <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

const AccountDetailsForm = () => {
    return <>
        <div className="px-1 px-lg-4">
            <p>
                <span className="text-dark-teal fw-bold">Please enter</span>your disburdement account details.
            </p>
            <p>
                <span className="text-red fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
            </p>
        </div>
        <div className="p-1 p-lg-5">
            <div className="">
                <form action="">
                    <div className="row">
                        <div className="col-md px-0 px-lg-4">
                            <label htmlFor="account-number">Account Number <span className="text-red">*</span></label>
                            <input id="account-number" name="accountNumber" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                        </div>
                        <div className="col-md px-0 px-lg-4">
                            <label htmlFor="bvn">BVN <span className="text-red">*</span></label>
                            <input id="bvn" name="bvn" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            <small className="text-disabled">To get your BVN, Dial *565*0#</small>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md px-0 px-lg-4">
                            <label htmlFor="gender">Bank Name <span className="text-red">*</span></label>
                            <select id="gender" name="gender" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" />
                        </div>
                        <div className="col-md px-0 px-lg-4">
                            <label htmlFor="account-nam">Account Name<span className="text-red">*</span></label>
                            <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="account-name" name="accountName" />
                        </div>
                    </div>

                    <div className="px-0 px-lg-4 mt-5">
                        <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                    </div>
                </form>
            </div>

        </div>
    </>
}

const NextOfKinForm = () => {
    return (
        <>
            <div className="px-1 px-lg-4">
                <p>
                    <span className="text-dark-teal fw-bold">Please enter</span>your next of kin details.
                </p>
                <p>
                    <span className="text-red fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
                </p>
            </div>
            <div className="p-1 p-lg-5">
                <div className="">
                    <form action="">
                        <div className="row">
                            <div className="col-md-8 px-0 px-lg-4">
                                <label htmlFor="full-name">Full Name <span className="text-red">*</span></label>
                                <input id="full-name" name="fullName" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="phone-number">Phone Number <span className="text-red">*</span></label>
                                <input id="phone-number" name="phoneNumber" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            </div>
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="relationship">Relationship <span className="text-red">*</span></label>
                                <input id="relationship" name="relationship" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-8 px-0 px-lg-4">
                                <label htmlFor="home-address">Home Address <span className="text-red">*</span></label>
                                <input id="home-address" name="homeAddress" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />

                            </div>
                        </div>

                        <div className="px-0 px-lg-4 mt-5">
                            <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

const EmploymentDetailsForm = () => {
    return (<>
        <div className="px-1 px-lg-4">
            <p>
                <span className="text-dark-teal fw-bold">Please enter</span>your employment information.
            </p>
            <p>
                <span className="text-red fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
            </p>
        </div>
        <div className="p-1 p-lg-5">
            <div className="">
                <form action="">
                    <div className="row">
                        <div className="col-md-8 px-0 px-lg-4">
                            <label htmlFor="ministry-name">Ministry/Org. Name<span className="text-red">*</span></label>
                            <input id="ministry-name" name="ministryName" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 px-0 px-lg-4">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="oracle-number">Oracle Number <span className="text-red">*</span></label>
                                <input id="oracle-number" name="oracleNumber" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 px-0 px-lg-4">
                            <div className="col-md px-0 px-lg-4">
                                <label htmlFor="department">Department</label>
                                <input id="department" name="department" type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                            </div>
                        </div>
                    </div>

                    <div className="px-0 px-lg-4 mt-5">
                        <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                    </div>
                </form>
            </div>

        </div>
    </>)
}

const DocumentUploadForm = () => {
    return (
        <>
            <div className="px-1 px-lg-4">
                <p>
                    <span className="text-dark-teal fw-bold">Please enter</span>your employment information.
                </p>
                <p>
                    <span className="text-red fw-bold">NOTE:</span> It is compulsory that you fill all aestheric fields <br /> beform you can continue to the next application form.
                </p>
            </div>
            <div className="p-1 p-lg-5">
                <div className="px-0 px-lg-4">
                    <div className="row gy-5">
                        <div className="col-md-6">
                            <FileDrapDrop title="Staff ID" description="your staff ID" />
                        </div>
                        <div className="col-md-6">
                            <FileDrapDrop title="Valid ID" description="a gorvernment issued identification card" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <FileDrapDrop title="Employment/Confirmation Letter" description="a file" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <FileDrapDrop title="Passport Photo" description="a clear image of your face" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <FileDrapDrop title="Statement Of Account" description="your recent statement, Preferably in PDF" />
                        </div>
                        <div className="col-md-6 mt-5">
                            <FileDrapDrop title="Pay Slips" description="your most recent pay slips" />
                        </div>
                    </div>
                    <div className="form-check mt-5">
                        <input className="form-check-input" type="checkbox" value="" id="terms" />
                        <label className="form-check-label" htmlFor="terms">
                            I have read, understood and accept all the <a href=""> terms and conditions  </a>for QuickFund Microfinance Bank.
                        </label>
                    </div>

                    <div className="px-0 px-lg-4 mt-5">
                        <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Submit</button>
                    </div>
                </div>

            </div>
        </>
    )
}

const OracleForm = () => {

    const [activeForm, setActiveForm] = useState({ form: "personalDetails" });
    const [currentIndex, setCurrentIndex] = useState({ index: 0 });

    const handleFormSelect = (value: string, index: number) => {
        return () => {
            setCurrentIndex({ index });
            setActiveForm({ form: value });
        }
    }

    return (
        <div className="px-1 px-md-3 px-lg-5 py-5">
            <div className="px-1 px-md-3 px-lg-5 mb-4">
                <div className="d-flex px-1 px-md-3 px-lg-5 justify-content-evenly loan-icons-container">
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("personalDetails", 0)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 0 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path fill={"black"} fillOpacity="0.5" d="M42.5 43.4995H16.5C15.6652 43.5245 14.8546 43.2177 14.2455 42.6464C13.6364 42.0751 13.2784 41.2857 13.25 40.451V20.5464C13.2784 19.7119 13.6364 18.9226 14.2456 18.3516C14.8547 17.7805 15.6654 17.4741 16.5 17.4995H42.5C43.3346 17.4741 44.1453 17.7805 44.7544 18.3516C45.3636 18.9226 45.7216 19.7119 45.75 20.5464V40.4526C45.7212 41.287 45.363 42.076 44.754 42.647C44.1449 43.218 43.3345 43.5245 42.5 43.4995ZM16.5 20.7495V40.2316L42.5 40.2495V20.7674L16.5 20.7495ZM31.8237 36.9995H19.75C19.8695 35.6541 20.5062 34.4075 21.5261 33.522C22.6617 32.3954 24.1876 31.7495 25.7869 31.7183C27.3859 31.7508 28.9117 32.3959 30.0476 33.522C31.0673 34.4077 31.7039 35.6542 31.8237 36.9995ZM39.25 35.3745H34.375V32.1245H39.25V35.3745ZM25.7869 30.4995C25.356 30.5145 24.9267 30.4407 24.5257 30.2826C24.1246 30.1246 23.7603 29.8857 23.4555 29.5809C23.1506 29.2761 22.9118 28.9118 22.7538 28.5107C22.5957 28.1096 22.5219 27.6803 22.5369 27.2495C22.5221 26.8187 22.5961 26.3895 22.7542 25.9886C22.9124 25.5876 23.1512 25.2234 23.456 24.9186C23.7608 24.6139 24.125 24.375 24.5259 24.2169C24.9269 24.0588 25.3561 23.9848 25.7869 23.9995C26.2176 23.9848 26.6468 24.0588 27.0478 24.2169C27.4488 24.375 27.813 24.6139 28.1177 24.9186C28.4225 25.2234 28.6614 25.5876 28.8195 25.9886C28.9776 26.3895 29.0516 26.8187 29.0369 27.2495C29.0518 27.6803 28.978 28.1096 28.82 28.5107C28.662 28.9118 28.4231 29.2761 28.1183 29.5809C27.8135 29.8857 27.4492 30.1246 27.0481 30.2826C26.647 30.4407 26.2177 30.5145 25.7869 30.4995ZM39.25 28.8745H32.75V25.6245H39.25V28.8745Z" />
                            </svg>
                        </div>
                        <span className="fw-bold mt-1 mt-lg-3">Personal Details</span>
                    </div>
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("loanAmount", 1)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 1 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path fill="black" fillOpacity="0.5" d="M44.125 22.83V19.125C44.125 17.3375 42.6625 15.875 40.875 15.875H18.125C17.263 15.875 16.4364 16.2174 15.8269 16.8269C15.2174 17.4364 14.875 18.263 14.875 19.125V41.875C14.875 42.737 15.2174 43.5636 15.8269 44.1731C16.4364 44.7826 17.263 45.125 18.125 45.125H40.875C42.6625 45.125 44.125 43.6625 44.125 41.875V38.17C44.6162 37.8864 45.0246 37.4792 45.3097 36.9889C45.5948 36.4986 45.7466 35.9422 45.75 35.375V25.625C45.7466 25.0578 45.5948 24.5014 45.3097 24.0111C45.0246 23.5208 44.6162 23.1136 44.125 22.83ZM42.5 25.625V35.375H31.125V25.625H42.5ZM18.125 41.875V19.125H40.875V22.375H31.125C29.3375 22.375 27.875 23.8375 27.875 25.625V35.375C27.875 37.1625 29.3375 38.625 31.125 38.625H40.875V41.875H18.125Z" />
                            </svg>
                        </div>
                        <span className="fw-bold mt-1 mt-lg-3">Loan Amount</span>
                    </div>
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("accountDetails", 2)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 2 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path fill="black" fillOpacity="0.5" d="M42.5 17.5H16.5C14.7076 17.5 13.25 18.9576 13.25 20.75V40.25C13.25 42.0424 14.7076 43.5 16.5 43.5H42.5C44.2924 43.5 45.75 42.0424 45.75 40.25V20.75C45.75 18.9576 44.2924 17.5 42.5 17.5ZM30.3125 37C29.2351 37 28.2017 36.572 27.4399 35.8101C26.678 35.0483 26.25 34.0149 26.25 32.9375C26.25 31.8601 26.678 30.8267 27.4399 30.0649C28.2017 29.303 29.2351 28.875 30.3125 28.875C31.1943 28.877 32.051 29.1694 32.75 29.707C31.7685 30.448 31.125 31.6131 31.125 32.9375C31.125 34.2619 31.7685 35.427 32.75 36.168C32.051 36.7056 31.1943 36.998 30.3125 37V37ZM36.8125 37C35.7351 37 34.7017 36.572 33.9399 35.8101C33.178 35.0483 32.75 34.0149 32.75 32.9375C32.75 31.8601 33.178 30.8267 33.9399 30.0649C34.7017 29.303 35.7351 28.875 36.8125 28.875C37.8899 28.875 38.9233 29.303 39.6851 30.0649C40.447 30.8267 40.875 31.8601 40.875 32.9375C40.875 34.0149 40.447 35.0483 39.6851 35.8101C38.9233 36.572 37.8899 37 36.8125 37Z" />
                            </svg>
                        </div>
                        <span className="fw-bold mt-1 mt-lg-3">Account Details</span>
                    </div>
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("nextOfKin", 3)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 3 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path fill="black" fillOpacity="0.5" d="M37.625 43.5H16.5V22.375C16.5 21.4813 15.7688 20.75 14.875 20.75C13.9813 20.75 13.25 21.4813 13.25 22.375V43.5C13.25 45.2875 14.7125 46.75 16.5 46.75H37.625C38.5188 46.75 39.25 46.0188 39.25 45.125C39.25 44.2313 38.5188 43.5 37.625 43.5ZM42.5 14.25H23C21.2125 14.25 19.75 15.7125 19.75 17.5V37C19.75 38.7875 21.2125 40.25 23 40.25H42.5C44.2875 40.25 45.75 38.7875 45.75 37V17.5C45.75 15.7125 44.2875 14.25 42.5 14.25ZM32.75 19.125C35.4475 19.125 37.625 21.3025 37.625 24C37.625 26.6975 35.4475 28.875 32.75 28.875C30.0525 28.875 27.875 26.6975 27.875 24C27.875 21.3025 30.0525 19.125 32.75 19.125ZM22.61 37C24.9988 34.0263 28.6388 32.125 32.75 32.125C36.8613 32.125 40.5013 34.0263 42.89 37H22.61Z" />
                            </svg>

                        </div>
                        <span className="fw-bold mt-1 mt-lg-3">Next Of Kin</span>
                    </div>
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("employmentDetails", 4)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 4 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path d="M43.0111 48.1836C43.0111 50.2369 41.4732 51.8997 39.5738 51.8997H17.6606C15.7632 51.8997 14.2246 50.2362 14.2246 48.1836V14.6752C14.2246 12.6199 15.7639 10.957 17.6606 10.957H39.5738C41.4732 10.957 43.0111 12.6199 43.0111 14.6752V48.1836Z" fill="black" fill-opacity="0.5" />
                                <path d="M28.7603 16.0607C28.7603 16.4511 27.9822 16.7669 27.0268 16.7669H15.9604C15.0023 16.7669 14.2256 16.4511 14.2256 16.0607V9.7048C14.2256 9.31578 15.0016 9 15.9604 9H27.0268C27.9822 9 28.7603 9.31578 28.7603 9.7048V16.0607Z" fill="black" fill-opacity="0.5" />
                                <path d="M44.7262 46.6255C44.7262 48.3676 43.1728 49.7766 41.2546 49.7766H19.1102C17.1947 49.7766 15.6387 48.367 15.6387 46.6255V18.2253C15.6387 16.4845 17.1947 15.0742 19.1102 15.0742H41.2546C43.1728 15.0742 44.7262 16.4852 44.7262 18.2253V46.6255Z" fill="#D0D2D3" fill-opacity="0.5" />
                                <path d="M44.7262 44.4034C44.7262 46.1456 43.1728 47.5545 41.2546 47.5545H19.1102C17.1947 47.5545 15.6387 46.1449 15.6387 44.4034V16.0033C15.6387 14.2624 17.1947 12.8535 19.1102 12.8535H41.2546C43.1728 12.8535 44.7262 14.2631 44.7262 16.0033V44.4034Z" fill="black" fill-opacity="0.5" />
                                <path d="M36.9576 14.5632C36.9576 14.7729 35.9243 14.9435 34.6531 14.9435H19.9391C18.6652 14.9435 17.6318 14.7729 17.6318 14.5632V11.14C17.6318 10.9284 18.6652 10.7598 19.9391 10.7598H34.6531C35.9243 10.7598 36.9576 10.9284 36.9576 11.14V14.5632Z" fill="#D0D2D3" fill-opacity="0.5" />
                                <path d="M21.4845 21.1139H40.6746C41.966 21.1139 41.966 19.2998 40.6746 19.2998H21.4845C20.1979 19.2998 20.1979 21.1139 21.4845 21.1139Z" fill="white" fill-opacity="0.5" />
                                <path d="M21.4845 24.799H40.6746C41.966 24.799 41.966 22.9863 40.6746 22.9863H21.4845C20.1979 22.9863 20.1979 24.799 21.4845 24.799Z" fill="white" fill-opacity="0.5" />
                                <path d="M21.4845 28.565H40.6746C41.966 28.565 41.966 26.751 40.6746 26.751H21.4845C20.1979 26.751 20.1979 28.565 21.4845 28.565Z" fill="white" fill-opacity="0.5" />
                                <path d="M21.4845 32.2463H40.6746C41.966 32.2463 41.966 30.4336 40.6746 30.4336H21.4845C20.1979 30.4336 20.1979 32.2463 21.4845 32.2463Z" fill="white" fill-opacity="0.5" />
                                <path d="M21.4845 39.3554H30.3183C31.6056 39.3554 31.6056 37.542 30.3183 37.542H21.4845C20.1979 37.5413 20.1979 39.3554 21.4845 39.3554Z" fill="white" fill-opacity="0.5" />
                            </svg>


                        </div>
                        <span className="fw-bold mt-1 mt-lg-3">Employemnt Details</span>
                    </div>
                    <div>
                        <div className="mb-1 mb-lg-3" style={{ cursor: "pointer" }} onClick={handleFormSelect("documentUpload", 5)}>
                            <svg className="loan-icon w-100" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="30" cy="30" r="30" fill={`${currentIndex.index == 5 ? "#AAAAAA" : "#A3DA73"}`} />
                                <path d="M31.8337 42.833V35.4997H37.3337L30.0003 26.333L22.667 35.4997H28.167V42.833H31.8337Z" fill="black" fill-opacity="0.5" />
                                <path d="M20.8337 42.8337H24.5003V39.167H20.8337C17.8013 39.167 15.3337 36.6993 15.3337 33.667C15.3337 31.093 17.5318 28.6143 20.2342 28.1395L21.2993 27.9525L21.6513 26.9295C22.9402 23.1693 26.1412 20.8337 30.0003 20.8337C35.0548 20.8337 39.167 24.9458 39.167 30.0003V31.8337H41.0003C43.0225 31.8337 44.667 33.4782 44.667 35.5003C44.667 37.5225 43.0225 39.167 41.0003 39.167H35.5003V42.8337H41.0003C45.0447 42.8337 48.3337 39.5447 48.3337 35.5003C48.3308 33.8569 47.7775 32.2619 46.7619 30.9699C45.7464 29.6778 44.3272 28.7633 42.731 28.3723C41.9298 22.062 36.527 17.167 30.0003 17.167C24.9477 17.167 20.5587 20.1205 18.5548 24.7753C14.6168 25.9523 11.667 29.6703 11.667 33.667C11.667 38.7215 15.7792 42.8337 20.8337 42.8337Z" fill="black" fill-opacity="0.5" />
                            </svg>
                        </div>
                        <span className="fw-bold mt-5">Document Upload</span>
                    </div>
                </div>
            </div>

            <div className="personal-details-form justify-content-center d-flex  container mt-5" style={{ minHeight: "50rem" }}>
                <div className="shadow col-11 rounded-3 p-3">
                    {activeForm.form == "documentUpload" || currentIndex.index == 0 && <PersonalDetailsForm />}
                    {activeForm.form == "employmentDetails" || currentIndex.index == 1 && <EmploymentDetailsForm />}
                    {activeForm.form == "nextOfKin" || currentIndex.index == 2 && <NextOfKinForm />}
                    {activeForm.form == "accountDetails" || currentIndex.index == 3 && <AccountDetailsForm />}
                    {activeForm.form == "loanAmount" || currentIndex.index == 4 && <LoanAmountForm />}
                    {activeForm.form == "personalDetails" || currentIndex.index == 5 && <DocumentUploadForm />}
                </div>
            </div>

        </div >
    )
}

const OracleLoan = () => {
    return (
        <div className="bills-and-more">
            <OracleLanding />
            <OracleForm />
            <HowItWorksSection />
            <OpenAccountSection />
            <Footer />
        </div>
    );
}

export default OracleLoan;