import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { TACImg } from "../../Assets/Images";
import AppsSection from "../Components/AppsSection";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import "./style.scss"

const TermsAndConditionSection = () => {
    return (
        <>
            <div className="py-5 tap-section">
                <div className="text-center">
                    <h2 className="text-dark-teal font-primary fw-bolder">
                        Terms And Condition
                    </h2>
                </div>
                <div className="mt-5 px-3 px-lg-5 text-center">
                    <img src={TACImg} className="img-fluid img" alt="terms" />
                </div>
            </div>
        </>
    )
}

const TermsAndCondition = () => {
    return (
        <div className="terms-and-policy">
            <TermsAndConditionSection />
            <SocialMediaSection useDark />
            <NewsLetterSection />
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    )
}

export default TermsAndCondition;