import { Link } from "react-router-dom";
import { Container } from "../Container";
import "./style.scss"

declare interface DescriptionCardProps {
	icon: string;
	iconWidth: number;
	iconHeight: number;
	title: string;
	url: string;
	bgColor?: string;
	color?: string;
	className?: string;
	titleBgColor?: string;
}

const DescriptionCard: React.FC<DescriptionCardProps> = (props) => {
	return (
		<Container className={`description-card ${props.className} bg-${props.bgColor} text-${props.color}`}>
			<div className=" d-flex flex-column justify-content-between h-100">
				<div>
					<img height={props.iconHeight} width={props.iconWidth} src={props.icon} />
				</div>
				<div className="mt-4 px-4">
					<Link to={`${props.url}`} className={`text-white d-block text-decoration-none fs-6 link title  bg-${props.titleBgColor} py-2 px-3 rounded-pill`}>{props.title}</Link>
				</div>
			</div>
		</Container>
	);
}


export default DescriptionCard;