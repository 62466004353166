import { DragDrop } from "../../Assets/Icons";
import { IphoneMock, OracleLandingImg, SalaryLoanLanding } from "../../Assets/Images";
import Footer from "../Components/Footer";
import HowItWorksSection from "../Components/HowItWorksSection";
import OpenAccountSection from "../Components/OpenAccountSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
// import "./style.scss"


const SalaryLanding = () => {
    return (
        <div className="bills-and-more-landing px-lg-4 px-1 px-lg-4 mt-5">
            <div className="mt-5">
                <h2 className="text-center font-primary fw-bold">
                    Get a <span className="text-red">Salary Loan</span> of up to
                </h2>
                <h2 className="text-center font-primary fw-bold">
                    <span className="font-primary fw-bold">NGN2,000,000</span> easily Today</h2>
            </div>
            <div className="row py-4">
                <div className="col-12 col-lg-6 mb-4">
                    <SaLaryForm />
                </div>
                <div className="col-12 col-lg-6 mt-4">
                    <div>
                        <h4 className="text-dark-teal fw-bold font-primary">
                            Requirements and eligibility
                        </h4>
                        <p className="fw-light">
                            Wе are the bank for the Millenials, Gen Z and Baby Boomers.
                            We are hеrе to рrоvіdе innovative fіnаnсіаl ѕоlutіоnѕ that meets
                            the financial needs of our customers. Our goal is to make banking
                            accessible to everyone and to provide different lending options by leveraging
                            technology and we also make gеttіng loans аn еаѕу and ѕtrеѕѕ-frее рrосеѕѕ!
                        </p>
                        <div className="ps-4 mt-5">
                            <span className="bg-gray-200 text-dark-teal px-3 py-2 font-primary fw-bolder rounded-3">
                                09090512482
                            </span>
                        </div>
                    </div>
                    <img src={SalaryLoanLanding} alt="" className="img img-fluid" />
                </div>
            </div>
        </div >
    )
}

const CalculatorForm = () => {
    return (
        <div className="shadow salary-form bg-white rounded-3 p-3">
            <div>
                <h4 className="font-primary">LOAN CALCULATOR</h4>
                <p>vitae lacus maximus, eu suscipit sapien egestas. Fusce
                    pulvinar augue vel felis elementum consectetu</p>
            </div>

            <div className="mt-5">
                <div className="mt-4">
                    <h5 className="font-primary fw-bold"><span className="text-dark-teal">How much</span> do you need? Access up to</h5>
                    <h4 style={{ color: "rgba(133, 241, 26, 0.59)" }} className="font-primary fw-bold">NGN2,000,000</h4>
                </div>
                <div className="col-md-9 px-0 px-lg-4">
                    <label htmlFor="purpose">Purpose of the loan?</label>
                    <input type="text" className="py-2 form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="status" name="status" />
                </div>
                <div className="col-md-9 mt-4 px-0 px-lg-4">
                    <div className="col-md-7 px-0 px-lg-4">
                        <label htmlFor="amount">Amount ( <span className="fw-bold">Min:</span> N50,000 - <span className="fw-bold">Max:</span> N385,000 )</label>
                        <input type="text" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="amount" name="amount" />
                    </div>
                    {/* <span>Your monthly income is required</span> */}
                </div>

                <div className="col-md-9 mt-4 px-0 px-lg-4 d-flex justify-content-between">
                    <div className="col-3">
                        <label htmlFor="tenure">Amount ( <span className="fw-bold">Min:</span> 3 Months - <span className="fw-bold">Max:</span> 12 Months )</label>
                        <select id="tenure" name="tenure" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" >
                        </select>
                    </div>
                </div>

                <div className="mt-5">
                    <h6 className="fw-bold text-center text-dark-teal">
                        Proposed Monthly Repayment
                    </h6>
                    <div className="mt-2">
                        <h2 className="text-center">
                            NGN24,000
                        </h2>
                    </div>
                </div>

                <div className="mt-5">
                    <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn w-100 fw-bold px-3 px-lg-4">Apply</button>
                </div>
            </div>
        </div>
    )
}

const SaLaryForm = () => {
    return (
        <div className="shadow salary-form bg-white rounded-3 py-4 p-3">
            <div>
                <p className="fw-bold"><span className="text-dark-teal">Get Started</span> with basic information about yourself
                    to allow us know how much you con get and the convenient repayment schedule designed for you.</p>
            </div>
            <div className="mt-5">
                <div className="col-md-9 px-0 px-lg-4">
                    <label htmlFor="status font-primary">Employment Status</label>
                    <input type="text" className="py-2 form-control bg-secondary bg-opacity-25 border-0 shadow-none" id="status" name="status" />
                </div>
                <div className="col-md-9 mt-5 px-0 px-lg-4">
                    <label htmlFor="income">How much do you earn per month?</label>
                    <input type="text" id="income" name="income" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                    {/* <span>Your monthly income is required</span> */}
                </div>
                <div className="col-md-9 mt-5 px-0 px-lg-4 d-flex justify-content-between">
                    <div className="col-4">
                        <label htmlFor="phone">Phone Number?</label>
                        <input type="text" id="income" name="income" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                    </div>
                    <div className="col-4">
                        <label htmlFor="income">Date Of Birth <span className="text-red">*</span></label>
                        <select id="dob" name="dob" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" >
                        </select>
                    </div>
                    {/* <span>Your monthly income is required</span> */}
                </div>
                <div className="col-md-9 mt-5 px-0 px-lg-4 d-flex justify-content-between">
                    <div className="col-4">
                        <label htmlFor="phone">Phone Number?</label>
                        <input type="text" id="income" name="income" className="form-control bg-secondary bg-opacity-25 border-0 shadow-none" />
                    </div>
                    <div className="col-4">
                        <label htmlFor="income">Date Of Birth <span className="text-red">*</span></label>
                        <select id="dob" name="dob" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" >
                        </select>
                    </div>
                    {/* <span>Your monthly income is required</span> */}
                </div>
                <div className="col-md-9 mt-5 px-0 px-lg-4 d-flex justify-content-between">
                    <div className="col-3">
                        <label htmlFor="income">LGA<span className="text-red">*</span></label>
                        <select id="lga" name="lga" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" >
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="state">State <span className="text-red">*</span></label>
                        <select id="state" name="state" className="form-select bg-secondary bg-opacity-25 border-0 shadow-none" >
                        </select>
                    </div>
                    {/* <span>Your monthly income is required</span> */}
                </div>

                <div className="mt-5 mb-5 ps-4">
                    <button type="submit" style={{ backgroundColor: "rgba(133, 241, 26, 0.59)", color: "#282828" }} className="btn mb- 3 fw-bold px-3 px-lg-4">Next</button>
                </div>
                <div className="mb-5"></div>
            </div>
        </div>
    )
}

const SalaryLoan = () => {
    return (
        <div className="bills-and-more">
            <SalaryLanding />
            <HowItWorksSection />
            <OpenAccountSection />
            <Footer />
        </div>
    );
}

export default SalaryLoan;