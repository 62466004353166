import { USSDIcon } from "../../../Assets/Icons";
import "./style.scss"

const USSDButton = (props: { className?: string }) => {
    return (
        <button className={`app-btn text-dark  bg-white btn border-2 me-lg-3 d-inline-flex ${props.className}`}>
            <img width={24} height={24} src={USSDIcon} style={{ position: "relative", left: -5 }} />
            <p className="ms-2 m-0 small" style={{ position: "relative", left: -7, lineHeight: "15px" }}> Offline Banking
                <br />

                *614*448#
            </p>
        </button>
    );
}

export default USSDButton;