export const Container: React.FC<React.ComponentProps<'div'>> = (props) => {
	return (
		<div {...props} className={`container ${props.className}`} >
			{
				props.children
			}
		</div>
	);
}

export const ContainerFluid: React.FC<React.ComponentProps<'div'>> = (props) => {
	return (
		<div className={`container-fluid ${props.className}`}>
			{
				props.children
			}
		</div>
	);
}
