import { AboutLandingImg, IphoneMock, VisionManImg } from "../../Assets/Images";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
import "./style.scss"

declare interface DetailCardProps {
    heading: string;
    img: string;
    detial: string;
}


const DetailCard = () => {
    return (
        <div className="vision-card d-flex rounded-3 py-4 px-2">
            <div className="me-4 align-self-center">
                <img src={VisionManImg} alt="" width={100} className="img detail-img" />
            </div>
            <div>
                <h5 className="text-dark-teal mb-2">Our Visions and Values</h5>
                <p>
                    Our vision is to be the preferred consumer finance partner
                    for our customers. The key values that
                    drive our being are excellence, integrity, service and teamwork.
                </p>
            </div>
        </div>
    );
}

const OpenAccountSection = () => {
    return (
        <div className="open-account-section px-3 mt-5">
            <div className="d-flex bg-dark-teal pt-5 px-5 justify-content-evenly rounded-4 flex-column flex-lg-row">
                <div>
                    <img src={IphoneMock} alt="" className="img-fluid" />
                </div>
                <div className="align-self-center text-white mt-4">
                    <h1 className="text-white mb-5 font-secondary">
                        Open an account for free
                    </h1>
                    <h6>
                        Download the QuickFund app for free </h6>
                    <h6>today and enjoy seamless banking and
                        credit facilities.
                    </h6>
                    <div className="mt-4">
                        <AppStoreButton />
                        <GooglePlayButton />
                    </div>
                </div>
            </div>
        </div >
    )
}

const DetailSection = () => {
    return (
        <div className="detail-section px-2">
            <div className="row">
                <div className="col-lg-6 col-12 mt-3">
                    <DetailCard />
                </div>
                <div className="col-lg-6 col-12 mt-3">
                    <DetailCard />

                </div>
                <div className="col-lg-6 col-12 mt-3">
                    <DetailCard />

                </div>
                <div className="col-lg-6 col-12 mt-3">
                    <DetailCard />

                </div>
            </div>

        </div>
    )
}

const SavingsAndTransferLanding = () => {
    return (
        <div className="savings-landing px-lg-4 px-2 mt-5">
            <div className="row py-4">
                <div className="col-12 col-lg-6 align-self-center mb-4">
                    <h3 className="fw-bolder">
                        WHO ARE WE?
                    </h3>
                    <p className="who-are-we">
                        We are the bank for the Millennials, Gen Z and the Baby Boomers.
                        Quikfund is here to provide innovative financial solutions that
                        meet the financial needs of our customers. Our goal is to make
                        banking accessible to everyone and to provide different lending options.
                        QuickFund makes banking and getting loans easy and stress-free by
                        leveraging technology to attend to the ever-increasing needs of a
                        tech savvy generation.

                    </p>

                </div>
                <div className="col-12 col-lg-6">
                    <img src={AboutLandingImg} alt="" className="img img-fluid" />
                </div>
            </div>
        </div>
    )
}


const SavingsAndTransfer = () => {
    return (
        <div className="about">
            <SavingsAndTransferLanding />
            <DetailSection />
            <OpenAccountSection />
            <NewsLetterSection />
            <NoteSection />
            <Footer />
        </div>
    );
}

export default SavingsAndTransfer;