import "./style.scss"
declare interface Props {
    title: string;
    description: string;
    top?: boolean
}

const NoteCard: React.FC<Props> = (props) => {
    return (
        <div className="note-card h-100 rounded-4 bg-teal p-3">
            <div className="mt-2 p-3">
                <svg width="162" height="28" viewBox="0 0 162 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2.50049H160L2 26.0005H160" stroke="#E33838" strokeWidth="4" strokeLinecap="round" />
                    <path d="M2 2.50049H160L2 26.0005H160" stroke="#E33838" strokeWidth="4" strokeLinecap="round" />
                    <path d="M2 2.50049H160L2 26.0005H160" stroke="#E33838" strokeWidth="4" strokeLinecap="round" />
                    <path d="M2 2.50049H160L2 26.0005H160" stroke="#E33838" strokeWidth="4" strokeLinecap="round" />
                </svg>
            </div>
            <div>
                <h5 className="text-dark-teal fw-bolder font-primary  p-0 mb-2">{props.title}</h5>
                {/* <h5 className="text-dark-teal fw-bolder font-primary">Individuals and Entrepreneurs</h5> */}
                <p >
                    {
                        props.description
                    }
                </p>
            </div>
        </div>
    )
}

export default NoteCard;