import { HTMLAttributes, useEffect, useRef, useState } from "react";
import AppsSection from "../Components/AppsSection";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import "./style.scss"

type termsAndPolicyTab = "termsAndCondition" | "privacyPolicy";

const TermsAndPolicySection = () => {
    const [activeTab, setActiveTab] = useState<{ tab: termsAndPolicyTab }>({ tab: "termsAndCondition" });

    return (
        <>
            <div className="py-5 tap-section">
                <div className="text-center">
                    <h2 className="text-dark-teal font-primary fw-bolder">
                        Privacy Policy
                    </h2>
                </div>
                <div className="mt-5 px-3 px-lg-5">
                    <div className="px-0 lg-px-5 ">
                        <div className="container">
                            <div className="px-5">
                                <div className="wpb_wrapper">
                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h2><span ><b>Privacy Notices for QuickFund MFB </b></span></h2>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5">QuickFund MFB  (hereinafter referred to as QuickFund MFB) takes its customers’ privacy very seriously. This Privacy Notice stipulates the basis for the collection, use and disclosure of personal data by QuickFund MFB and its subsidiaries in line with the Nigerian Data Protection Regulation 2019 (NDPR).</p>
                                            <p className="fs-5">Personal Data comprises all the details we hold or collect on our employees, customers, stakeholders, vendors and other interested parties, directly or indirectly and includes any offline or online data that makes a person identifiable such as names, addresses, phone number, passport ID, usernames, passwords, digital footprints, photographs, financial data, assets and liabilities, insurance, savings and investments, health and high-risk information about products and services purchased from us. This data may be received from third parties or collected using our website(s), mobile applications.</p>
                                            <p className="fs-5">With this policy, we ensure USSD code and other digital channels through which&nbsp;we gather, store and handle data are fair, transparent and safeguard individual rights.&nbsp;For the purpose of this Privacy Notice, references to “<strong>we</strong>” or “<strong>us</strong>” shall refer to QuickFund MFB  and its entities/subsidiaries.</p>
                                            <p className="fs-5">This Privacy Notice should be brought to the attention of any party who is included in your Policy or on whose instruction you are providing us with their personal data.&nbsp;By providing your personal information or the personal information of someone included in your account information, you acknowledge that we may use it only in the ways set out in this Privacy Notice. We may provide you with further notices highlighting certain uses we wish to make of your personal information.</p>
                                            <p className="fs-5">From time to time, we may need to make changes to this privacy notice, for example, as the result of government regulation, new technologies, or other developments in the data protection laws regime generally. You should check the QuickFund MFB website periodically to view the most up to date privacy notice.</p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>1. Definitions</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5">“<strong>Data Controller</strong>” means the publisher and owner of the Website and the party also responsible for the collection of data.</p>
                                            <p className="fs-5">“<strong>Data Protection Officer</strong>” means the party responsible for overseeing data protection strategy and implementation to ensure compliance.</p>
                                            <p className="fs-5">“<strong>Content</strong>” means any content, writing, images, audio visual content or other information published on this Website.</p>
                                            <p className="fs-5">“<strong>Children” </strong>means persons under the age of majority in Nigeria (which is 18 years)</p>
                                            <p className="fs-5">‘’<strong>” </strong>means the parent company and/ or the various&nbsp; subsidiaries of QuickFund MFB </p>
                                            <p className="fs-5"><strong>“Legal basis” </strong>is the foundation for data processing under the NDPR. It is the specific&nbsp;<strong>legal</strong>&nbsp;grounds for the processing of data by an organisation.</p>
                                            <p className="fs-5">“<strong>Parties</strong>” means both You (the user of the Service) and the Data Controller: VP Corporate Services.</p>
                                            <p className="fs-5">“<strong>Personal Data</strong>” means any personal information collected for you in relation with your use of this service which is capable of identifying you.</p>
                                            <p className="fs-5">“<strong>Service</strong>” means the Website, which is known as: www.QuickFund MFB.com including all pages, sub-pages, all blogs, forums and other connected internet content whatsoever.</p>
                                            <p className="fs-5">“<strong>Services</strong>” means the services offered on the Website.</p>
                                            <p className="fs-5">“<strong>Third Parties” </strong><strong>means</strong>&nbsp;a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorized to process personal data.</p>
                                            <p className="fs-5">“<strong>You, Yours</strong>” means the user of this Website.</p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>2. Introduction</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">This Privacy Notice is designed to inform You about the Personal Data we collect, how we collect this data, the use of the data, and your rights relating to the personal data when you use this service or purchase the services offered on the Website.</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">We are committed to protecting your Personal Data while You use this Website.</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">By continuing to use our Website, You acknowledge that You have reviewed the Privacy Policy and agree to its terms. This also means that You have consented to the use of Your Personal Data and have accepted the applicable disclosures.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>3. Contact</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div></div>
                                            <div>
                                                <p className="fs-5">The operator who is also the party responsible for the collection of data is as follows: QuickFund MFB  and can be contacted as follows:</p>
                                            </div>
                                            <p ><span >20 Channels Drive, OPIC Estate, Isheri, Ogun State</span></p>
                                            <p className="fs-5"><span >The Data Protection Officer is as follows: QuickFund MFB  and can be contacted as follows:</span></p>
                                            <p ><span >20 Channels Drive, OPIC Estate, Isheri, Ogun State</span></p>
                                            <div>
                                                <p className="fs-5">By continuing to use our Website, You acknowledge that You have reviewed the Privacy Policy and agree to its terms. This also means that You have consented to the use of Your Personal Data and have accepted the applicable disclosures.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>4. The Personal Data We Collect From You</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5"><span >We collect various information to enable us provide good Service to all our users. Depending on how our Service will be used, the different types of Personal Data we collect are as follows:</span></p>
                                            <p >A. <b>All users</b><span >: We will collect passive information from all users. These information include cookies, IP address information, location information and certain browser information.</span></p>
                                            <p >B. <b>Sales and billing information</b><span >: We may collect Your credit and/or debit card information, which includes Your card number, password, etc, Your billing address, Your contact address and other information required for Your purchases.</span></p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>5. The Personal Data We Collect As You Use Our Service</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <h3>The information that we collect will depend on our relationship with you</h3>
                                            </div>
                                            <div>
                                                <p className="fs-5">We use the following to collect Personal Data from you:</p>
                                            </div>
                                            <div>
                                                <p className="fs-5"><b>Log Data</b>: We also use log files which store automatic information collected when users visit this Website. The log data which may be collected are as follows:</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(I) the domain and host from which You access the Website;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(II) name of the Internet Service Provider (ISP);</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(III) date and time of visit;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(IV) Your computer operating system and browser software;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(V) web pages visited, the duration, and frequency of visits;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(VI) Your Internet Protocol (IP) address.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>6.&nbsp;</b><b>Privacy of Children</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5">We respect the privacy of children. We do not knowingly collect names, email addresses or any other personally identifiable information from children except where this is required to register them as beneficiaries of our services or products.</p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>7.&nbsp;</b><b>How do we use your personal information?</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">We collect and use Your Personal Data for the following reasons:</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(I) to provide our Service and to maintain and make improvements to the Service we provide to You;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(II) to develop new Services on the Website;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(III) to provide personalized Service to You, including making recommendations and providing personalized content;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(IV) to provide customer service to You;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(V) to provide analytics to understand how our Service is used.</p>
                                            </div>
                                            <p className="fs-5">(VI) to conduct internal performance analyses of QuickFund MFB Employees using personal data such as Name, Gender, Address, Phone Number, Marital status, Work Experience, Dependencies.</p>
                                            <p className="fs-5">&nbsp;</p>
                                            <p className="fs-5"><strong>Legal Basis</strong></p>
                                            <p className="fs-5">Under the Nigeria Data Protection Regulation 2019 and equivalent data protection laws around the world. we need a lawful basis to use and process your personal information.</p>
                                            <p className="fs-5">We have set out below the main reasons why we process your personal information and the applicable circumstances when we will do so:</p>
                                            <ul>
                                                <li>Processing is necessary in order for us to provide your banking support services which is based on the legal basis of contract, these include the following:</li>
                                            </ul>
                                            <ol>
                                                <li className="lis list-none" >
                                                    <ol>
                                                        <li >
                                                            <ol>
                                                                <li>QuickFund MFB Core: This provides retail and commercial banks with cloud-based software for running core operations like accounting, deposits, loans, and customer interactions in a fully digital and automated way.</li>
                                                                <li>QuickFund MFB Cards: This helps to digitise and automate card operations across banks with the objective to reduce cost while delivering speed and convenience to account holders.</li>
                                                                <li>QuickFund MFB Lend: This automates the entire lending value chain from origination to disbursement and ultimately repayment collection.</li>
                                                                <li>QuickFund MFB POS: Point of Sale Transactions helps facilitate &nbsp;technology that allows agents of banks deliver financial services to underbanked individuals in locations where there are no bank branches.</li>
                                                                <li>QuickFund MFB Switch: This operates a decentralised interbank payment network powered by blockchain and designed to become the payment infrastructure for Africa’s cashless future.</li>
                                                            </ol>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                            <ul>
                                                <li>We may use cloud storage solutions within or outside Nigeria which are chosen to ensure efficiency and improved performance through up to date technology. It is our legitimate interest to do so.</li>
                                                <li>Nigeria Information Technology Development Agency (NITDA) may require us to retain records of our dealings with you, this will be based on legal obligation.</li>
                                                <li>To comply with: local or foreign laws, regulations, voluntary codes, directives, judgments or court orders, agreements between any member of QuickFund MFB  and any authority, regulator, or enforcement agency; policies (including the QuickFund MFB ’s policies), good practice, government sanctions or embargoes, reporting requirements under financial transactions legislation and demands or requests of any authority, regulator, tribunal, enforcement agencies [ i.e. the Nigeria Financial Intelligent Unit (“NFIU”) and the Economic and Financial Crime Commission (“EFCC”), or exchange body, this processing activity will be based on a legal obligation.</li>
                                                <li>Where we need to use your personal information to establish, exercise or defend our legal rights, for example when we are faced with any legal claims or where we want to pursue any legal claims ourselves. This can be based on either our legitimate interest to defend ourselves or terms of our contract with you.</li>
                                                <li>Where we need to use your personal information for reasons of substantial public interest, such as investigating fraudulent claims and carrying out fraud, credit and anti-money laundering checks, identification checks. We process this sort of information based on legitimate interest to protect ourselves from fraud as well as a legal obligation to implement anti-money laundering safeguards.</li>
                                                <li>Where we need to communicate with you to resolve complaints or other issues based on our contractual obligations with you.</li>
                                                <li>Where you have provided your consent to our use of your personal information. We will usually only ask for your consent in relation to processing your sensitive personal information or when providing marketing information to you (including information about other products and services). This will be made clear when you provide your personal information. If we ask for your consent, we will explain why it is necessary.</li>
                                                <li>Where we have appropriate legitimate business, we need to use your personal information such as maintaining our business records, developing and improving our products and services, sharing data with contracted third parties all whilst ensuring that such business need does not interfere with your rights and freedoms and does not cause you any harm.</li>
                                                <li>Where we need to use your sensitive personal information such as health data because it is necessary for your vital interests, this being a life or death matter.</li>
                                            </ul>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>8. </b><b>Who do we share your personal information with?</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5">We might share your personal information with two types of organisations – companies inside the QuickFund MFB , and other third parties outside the . For further details of disclosures, please see below. We won’t share any of your personal information other than for the purposes described in this Privacy Notice. If we share anything outside the , it’ll be kept strictly confidential and will only be used for reasons that we’ve described.</p>
                                            <p className="fs-5">&nbsp;</p>
                                            <p className="fs-5"><strong>8.1 Disclosures within our </strong></p>
                                            <p className="fs-5">In order to provide our services, your personal information may be shared with other companies in the QuickFund MFB . Your personal information might be shared for our general business administration, efficiency and accuracy purposes or for the prevention and detection of fraud.</p>
                                            <p className="fs-5">We do not disclose Your Personal Data except for any of the following reasons:</p>
                                            <p className="fs-5">(I) if You have granted us the permission to do so: We will disclose Your Personal Data where we have received Your unequivocal consent and permission to do so. However, such consent may be withdrawn at anytime;</p>
                                            <p className="fs-5">(II) if we are required to do so by an existing law or regulation: We may also disclose and share Your Personal Data for the following reasons:</p>
                                            <ul>
                                                <li>
                                                    <ul>
                                                        <li>to meet any applicable law, regulation, legal process, or any legal request, such as subpoenas, court orders, requests for administrative or government bodies;</li>
                                                        <li>to enforce our applicable Terms of Use;</li>
                                                        <li>to detect, prevent, or address any fraud, security or technical issues;</li>
                                                        <li>to prosecute or bring any legal action against any user who has violated any law or our Terms of Use.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className="fs-5">(III) For any other reason that may be necessary and incidental for the operation of our Website.</p>
                                            <p className="fs-5">&nbsp;</p>
                                            <p className="fs-5"><strong>8.2. Disclosures to third parties</strong></p>
                                            <p className="fs-5">We also disclose your information to the third parties listed below for the purposes described in this Privacy Notice. This might include:</p>
                                            <ul>
                                                <li>Your relatives or, guardians (on your behalf where you are incapacitated or unable) or other people or organisations associated with you or your lawyer</li>
                                                <li>Where you have named an alternative contact (such as a relative) to speak with us on your behalf. Once you have told us your alternative contact, this person will be able to discuss all aspects of your policy (including claims and cancellation) with us and make changes on your behalf.</li>
                                                <li>Financial Institutions who are our clients for the provision of both banked and unbanked service provision.</li>
                                                <li>Agents to facilitate Agent Banking services</li>
                                                <li>Fraud detection agencies and other third parties who operate and maintain fraud detection registers</li>
                                                <li>The police and other third parties or law enforcement agencies where reasonably necessary for the prevention or detection of crime</li>
                                                <li>Central Bank of Nigeria</li>
                                                <li>Our third-party services providers such as IT suppliers, auditors, lawyers, marketing agencies, research specialists, document management providers and tax advisers.</li>
                                                <li>Customer satisfaction survey providers</li>
                                                <li>Disclosure of your personal information to a third party outside of the QuickFund MFB  will only be made where the third party has agreed to keep your information strictly confidential and shall only be used for the specific purpose for which we provide it to them.</li>
                                            </ul>
                                            <p className="fs-5">&nbsp;</p>
                                            <p className="fs-5">We may also disclose your personal information to other third parties where:</p>
                                            <ul>
                                                <li>We are required or permitted to do so by law or by regulatory bodies such as where there is a court order, statutory obligation.</li>
                                                <li>We believe that such disclosure is necessary in order to assist in the prevention or detection of any criminal action (including fraud) or is otherwise in the overriding public interest; or</li>
                                                <li>Exemptions under the data protection legislation allow us to do so.</li>
                                            </ul>
                                            <p className="fs-5">&nbsp;</p>
                                            <p className="fs-5"><strong>TRANSFERS OUTSIDE OF NIGERIA </strong></p>
                                            <p className="fs-5"><strong>&nbsp;</strong>Some of the recipients and technical solutions set out above may be in other countries outside Nigeria.</p>
                                            <p className="fs-5">Where we make a transfer of your personal information outside of Nigeria, in all cases where personal data is transferred to a country which is deemed not to have the same standards of protection for personal data as Nigeria, QuickFund MFB will ensure Appropriate Safeguards have been implemented to ensure that your personal information is protected where standards are not the same or similar to those standards within Nigeria. Such steps may include placing the party we are transferring personal information to under contractual obligations to protect it to adequate standards. Occasionally there may also be some circumstances where we are required to transfer your personal information outside of Nigeria and we shall rely on the basis of processing it for being necessary for the performance of your contract.</p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>9.&nbsp;</b><b>How long do we keep records for?</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <p className="fs-5">We keep your personal information for as long as reasonably necessary to fulfil the relevant purposes set out in this Privacy Notice and in order to comply with our legal and regulatory obligations. The length of time we retain personal information for depends on the purposes for which we collect and use it and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights. QuickFund MFB has a Data Retention Policy</p>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>10. Storage Of Personal Data</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">We take the security of the Personal Data we collect very seriously, and we take reasonable measures to reduce the risk of accidental destruction, loss or unauthorized access to such information. However, please note that no system involving the transmission of information via electronic storage systems or the internet is completely secure.</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">The Personal Data and any other information we have about You may be stored for such period as we may determine until You withdraw Your consent. We will also delete your data when you cease to be our customer subject to our contractual and legal obligations.</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">Note that You can withdraw Your consent to store Your Personal Data at any time. Once this is done, all Personal Data and information we have about You will be deleted.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b> 11. Protection Of Personal Data</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">As a PCI-DSS Compliant organisation, our Service is built with strong security features that continuously protects your Personal Data. Our security features help us detect and block security threats. If we detect any security risk, we may inform you and guide You through steps to stay protected. We also Encrypt information both at rest and in transit</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>12. Links To Third-Party Sites Or Services</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">The website may contain links to other websites which we believe may offer useful information. These linked websites are not under our control and this Privacy Policy does not apply to these websites. We suggest that You contact those websites directly for information on their privacy policy, security, data collection and distribution policies.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>13. Accessing, Modifying And Deleting Your Personal Data</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">If you wish to access, review, modify any information we have about You, You may do so by simply contacting us on the following email address:&nbsp;<a href="mailto:support@quickfund.com">support@quickfund.com</a>. You may also request that we delete any information belonging to You that we have stored.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>14. Your Rights</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">Your rights in relation to Your Personal Data are as follows:</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(I) the right to have access to Your Personal Data;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(II) the right to be informed about the processing of Your Personal Data;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(III) the right to rectify any inaccurate Personal Data or any information about You;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(IV) the right to review, modify or erase Your Personal Data and any other information we have about You;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(V) the right to restrict the processing of Your Personal Data;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(VI) the right to block Personal Data processing in violation of any law;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(VII) the right to be informed about any rectification or erasure of Personal Data or restriction of any processing carried out;</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(VIII) the right to the portability of Your Personal Data; and</p>
                                            </div>
                                            <div>
                                                <p className="fs-5">(IX) the right to lodge a complaint to a supervisory authority within Nigeria.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <h3 className="mt-5"><b>15. Contact Information</b></h3>
                                        </div>
                                    </div>




                                    <div className="wpb_text_column wpb_content_element ">
                                        <div className="wpb_wrapper">
                                            <div>
                                                <p className="fs-5">If You have any questions regarding this Privacy Policy or the Personal Data we collect, or if You wish to exercise any of the data subject rights listed above, make any comments or complaints about anything related to this Privacy Policy, please contact our Data Protection Officer on this email address:&nbsp;<a href="mailto:dpo@QuickFund MFB.com">dpo@QuickFund MFB.com</a>.</p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="divider-wrap" data-alignment="default"><div className="divider"></div></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const TermsAndPolicy = () => {
    return (
        <div className="terms-and-policy">
            <TermsAndPolicySection />
            <SocialMediaSection useDark />
            <NewsLetterSection />
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    )
}

export default TermsAndPolicy;