import { Link } from "react-router-dom";
import { AppleIcon, BritishAirwayLogo, DSTVLogo, EKEDCLogo, GooglePlayIcon, GOTVLogo, IkejaElecLogo, LIRSLogo, NetflixLogo, QuickTellerLogo, ShowMaxLogo, SmileLogo, StartimeLogo, USSDIcon, WhatsAppIcon } from "../../Assets/Icons";
import { AboutLandingImg, BillsAndMoreLandingImg, ClientImg, IphoneMock, VisionManImg } from "../../Assets/Images";
import TestmonialCard from "../../Components/TestmonialCard";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
import USSDButton from "../Components/StoreButtons/USSDButton";
import WhatsAppButton from "../Components/StoreButtons/WhatsAppButton";
import "./style.scss"

const BillsAndMoreLanding = () => {
    return (
        <div className="bills-and-more-landing px-lg-4 py-lg-6 px-4 my-5">
            <div className="row py-4 d-none d-lg-flex px-lg-5 mx-auto">
                <div className="col-12 col-lg-6 align-self-center mb-4">
                    <h5 className="display-6 font-primary fw-bolder fw-normal">
                        Do more with QuickFund App from the comfort of your space
                    </h5>
                    <p className="detail fs-5" style={{ textAlign: "justify", lineHeight: 1.8 }}>
                        We all have a lot to attend to in our daily lives that it is not
                        worthwhile to continue waiting at the bank just to carry out simple
                        ransactions. Things are now moving fast. Our mobile banking app has
                        been built for you! QuickFund banking app is easy to use and you enjoy
                        eamless services. You can conveniently pay bills, buy airtime, apply
                        for loans, make free transfers to other banks, and do lots more with
                        ease.
                    </p>
                    <p className="detail fs-5" style={{ textAlign: "justify", lineHeight: 1.8 }}>
                        QuickFund app also comes with outstanding features
                        that let you automate money decisions,
                        restrict your debit cards, get speedy responses to
                        your enquiries and more.
                    </p>
                    <div className="mt-4 d-flex justify-content-between">
                        <a href={"https://apps.apple.com/us/app/quickfund-mfb-mobile/id1627263878"} target="_blank" rel="noopener noreferrer" className={`app-btn  bg-white btn border-2`}>
                            <img width={14} height={14} src={AppleIcon} style={{ position: "relative", left: -5 }} />
                            <p className="d-inline" style={{ position: "relative", left: -7 }}> Download for IOS </p>
                        </a>
                        <button className={`app-btn  bg-white btn border-2 `}>
                            <img width={14} height={14} src={GooglePlayIcon} style={{ position: "relative", left: -5 }} />
                            <p className="d-inline" style={{ position: "relative", left: -7 }}> Download for Android </p>
                        </button>
                        <button className={`app-btn  bg-white btn border-2`}>
                            <img width={14} height={14} src={WhatsAppIcon} style={{ position: "relative", left: -5 }} />
                            <p className="d-inline" style={{ position: "relative", left: -7 }}>  09090512482 </p>
                        </button>
                        <button className={`app-btn text-dark  bg-white btn border-2 d-inline-flex`}>
                            <div style={{ height: "100%" }}>
                                <img width={14} height={14} src={USSDIcon} style={{ position: "relative", left: -5 }} />
                            </div>
                            <p className="ms-2 m-0" style={{ position: "relative", left: -7, lineHeight: "15px" }}> Offline Banking
                                <br />

                                *614*448#
                            </p>
                        </button>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mt-4">
                    <img src={BillsAndMoreLandingImg} alt="" className="w-full img img-fluid landing-img" />
                </div>
            </div>

            <div className="row py-4 d-flex d-lg-none">
                <div className="col-12 col-lg-6 align-self-center mb-4">
                    <h5 className="fs-2 text-center fw-normal">
                        Do more with QuickFund App from the comfort of your space
                    </h5>
                    <img src={BillsAndMoreLandingImg} alt="" className="mt-4 img img-fluid" />
                </div>
                <div className="col-12 col-lg-6 mt-4">
                    <p className="detail fs-5" style={{ textAlign: "justify", lineHeight: 1.8 }}>
                        We all have a lot to attend to in our daily lives that it is not
                        worthwhile to continue waiting at the bank just to carry out simple
                        ransactions. Things are now moving fast. Our mobile banking app has
                        been built for you! QuickFund banking app is easy to use and you enjoy
                        eamless services. You can conveniently pay bills, buy airtime, apply
                        for loans, make free transfers to other banks, and do lots more with
                        ease.
                    </p>
                    <p className="detail mt-4 fs-5" style={{ textAlign: "justify", lineHeight: 1.8 }}>
                        QuickFund app also comes with outstanding features
                        that let you automate money decisions,
                        restrict your debit cards, get speedy responses to
                        your enquiries and more.
                    </p>
                </div>
            </div>
        </div>
    )
}

const TopUpSection = () => {
    return (
        <div className="top-up-section py-5 text-center mt-5">
            <div className="mb-4 ">
                <h3 className="d-none d-lg-block lh-base text-black fs-1 font-primary fw-bold">Pay Bills and Top-Up with <br></br> ease from the comfort of your space</h3>
                <h2 className="d-block d-lg-none font-secpndary px-4 fw-bold">Pay Bills and Top-Up with <br></br> ease from the comfort of your space</h2>

            </div>
            <div className="d-flex justify-content-around col-12 col-lg-9 mx-auto mt-5">
                <div>
                    <img src={ShowMaxLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={GOTVLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={DSTVLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={SmileLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={IkejaElecLogo} alt="" className="img sponsor-img" width={120} />
                </div>
            </div>
            <div className="d-flex justify-content-around col-12 col-lg-11 mx-auto mt-5">
                <div>
                    <img src={StartimeLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={NetflixLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={QuickTellerLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={BritishAirwayLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={LIRSLogo} alt="" className="img sponsor-img" width={120} />
                </div>
                <div>
                    <img src={EKEDCLogo} alt="" className="img sponsor-img" width={120} />
                </div>
            </div>
        </div>
    )
}

const TestimonialSection = () => {
    return (
        <div className="d-flex justify-content-center px-5 mt-5 mt-5 py-5 border-top">
            <div className="col-lg-6 col-md-8 col-sm-11 col-12 py-4">
                <div className="bg-white testimonial-card position-relative rounded-4 shadow p-4">
                    <div className="client-img relative mb-4 d-flex justify-content-center">
                        <div className="h-[55px] w-[55px] profile rounded-full mx-auto  fw-bolder fs-5" style={{ width: 55, height: 55, borderRadius: "100%", margin: "auto" }}>
                            {"OJ"}
                        </div>
                    </div>
                    <div className="client-name text-center">
                        <h5 className="m-0 p-0 fw-bolder fs-6">Oluwakemi Joachim</h5>
                        <h6 className="text-secondary" style={{ fontSize: "11px" }}>
                            Lagos, Nigeria
                        </h6>
                    </div>
                    <div className="client-comment mt-4 text-center" >
                        <p className="fw-bolder text-start" style={{ fontSize: "0.9rem", lineHeight: 1.8 }}>
                            This is the best banking app! I'm enjoying seamless services. The loan
                            rate is not outrageous compared to other banks.
                            I've made QuickFund bank my main bank account and
                            I am excited to refer all my friends!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}


const BillsAndMore = () => {
    return (
        <div className="bills-and-more">
            <BillsAndMoreLanding />
            <TopUpSection />
            <div className="d-none d-lg-block">
                <TestimonialSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    );
}

export default BillsAndMore;