import { BranchIcon, CallCenterIcon, MailIcon } from "../../Assets/Icons";
import { BlogImg } from "../../Assets/Images";
import AppsSection from "../Components/AppsSection";
import BlogCard from "../Components/BlogCard";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import "./style.scss"

declare interface ContactCardProps {
    cardImg: string;
    cardTitle: string;
    cardDetail: string;
}

const ContactCard: React.FC<ContactCardProps> = (props) => {
    return (
        <div className="contact-card position-relative rounded-4 shadow p-3 bg-teal">
            <div className="card-img relative mb-5 d-flex justify-content-center">
                <img src={props.cardImg} className="img" />
            </div>
            <div className="card-title text-center mt-2">
                <h5 className="m-0 p-0 fw-bolder">{props.cardTitle}</h5>
            </div>
            <div className="card-detail mt-4 text-center" >
                <p>
                    {props.cardDetail}
                </p>
            </div>
        </div>
    )
}

const BlogPostInsights = () => {
    return (
        <div className="blog-post-insights p-2  mt-5">
            <div className=" px-lg-5 px-0 pt-5 text-center">
                <h2 className="font-primary fw-bolder">From our Blog</h2>
                <h5>
                    Explore our blog for insightful articles, personal reflections, impactful resources,
                    and ideas that inspire us at QuickFund
                </h5>
            </div>

            <div className="d-flex justify-content-around mt-5 flex-column flex-lg-row">
                <BlogCard
                    className="mt-5 align-self-center"
                    darkMode={false}
                    blogDate="January 25, 2022"
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
                <BlogCard
                    className="mt-5 align-self-center"
                    darkMode={false}
                    blogDate="January 25, 2022"
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
                <BlogCard
                    className="mt-5 align-self-center"
                    darkMode={false}
                    blogDate="January 25, 2022"
                    blogPost="How QuickFund is solving accesibilty to credit facilities in Nigeria."
                    blogImg={BlogImg} />
            </div>
        </div>
    )
}

const Article = () => {
    return (
        <div className="article">
            <BlogPostInsights />
            <div className="d-block d-lg-none">
                <SocialMediaSection useDark />
            </div>
            <NewsLetterSection />
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    );
}

export default Article;