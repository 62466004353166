
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import AppsSection from "../Components/AppsSection";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import "./style.scss"

declare interface CollapseProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    response: string | JSX.IntrinsicElements["div"];
    faqId: string;
}

declare interface SelectProps {
    items: string[]
    className?: string;
    bgColor?: string;
    onChange?: (value: string) => void

}

const Select: React.FC<SelectProps> = (props) => {
    const selectRef = useRef(HTMLDivElement.prototype);
    const [isActive, setIsActive] = useState(false);

    const handleMenuClick = () => {
        setIsActive(!isActive);
    }

    const handleMenuItemSelect = (value: string) => {
        let currentSelected = selectRef.current.children[0];
        currentSelected.innerHTML = value;
        setIsActive(false);
        if (props.onChange)
            props.onChange(value);
    }

    useEffect(() => {
        let currentSelected = selectRef.current.children[0];
        let selectItems = selectRef.current.children[1];

        currentSelected.innerHTML = selectItems.firstElementChild?.innerHTML as string;
    }, [])

    return (
        <div onClick={handleMenuClick} ref={selectRef} className={`select-container ${props.className} bg-teal position-relative ${isActive ? "active" : ""}`}>
            <div className="select-selected p-2 px-3 fw-bold position-relative">

            </div>

            <div className="select-items py-3 bg-white position-absolute w-100 shadow-sm" style={{}}>
                {
                    props.items.map((val) => <div onClick={() => handleMenuItemSelect(val)} className="fw-bold select-item py-2 px-3">{val}</div>)
                }
            </div>
        </div>
    )
}

export const CollapseCard: React.FC<CollapseProps> = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={props.className}>
            <a onClick={() => setOpen(!open)} href={`#${props.faqId}`} data-bs-toggle="collapse" role="button" className="shadow-none btn col-12 text-start" aria-expanded="false" aria-controls={`${props.faqId}`}>
                <div className="d-flex justify-content-between">
                    <h5 className="font-primary fw-bolder">{props.title}</h5>
                    <i className={`bi ${!open ? "bi-plus-circle" : "bi-dash-circle"}`}></i>
                </div>
            </a>
            <div className="collapse px-3" id={`${props.faqId}`}>
                <h6 className="lh-base fw-medium">
                    {props.response}
                </h6>
            </div>
        </div>
    )
}

<>
</>

const faqs = [
    {
        question: 'Who is QuickFund Bank?',
        answer: " QuickFund is a Microfinance bank licenced by CBN. We grants Loans, Savings, deposit, withdrawals and any other banking related activities."
    },
    {
        question: 'Is QuickFund a registered and licensed bank?',
        answer: "Yes, we are a microfinance banking license from the Central Bank of Nigeria. Our registration number is RC 1460935"
    },
    {
        question: "How do I open an account with QuickFund?",
        answer: "You can create an account via our app or walk in into our bank.",
    },
    {
        question: 'Is my money safe with QuickFund?',
        answer: "Yes, your money is safe with us.We secure all accounts with the same high- security encryption used by regular banks, we also insure every deposit you make into account."
    },

    {
        question: 'Can I have more than one account with the bank?',
        answer: "Yes, there's no limit to the number of accounts you can have."
    },
    {

        question: 'What are the transactions I can perform on the QuickFund App?',
        answer: "You can make transfer, request for loan, Bill payments, Airtime/ Data purchase, Transfers, Cable TV payments."
    },
    {
        question: 'Can I take a quick loan on the QuickFund app?',
        answer: "Yes you can, it takes less than 5 minutes to get a quick loan at a competitive Interest rate."
    },
    {
        question: 'Can I apply for a business loan from QuickFund?',
        answer: "Yes, if you have a business registered under CAC, you can easily request for a facility with us."
    },
    {
        question: 'Can I apply for a salary advance loan from QuickFund?',
        answer: "Yes, as an employee to a reputable organisation, you can get a salary advance loan from QuickFund.",
    },
    {
        question: 'How much loan can I apply for?',
        answer: "Minimum of 50k maximum of 5m",
    },
    {
        question: 'What is public sector loan?',
        answer: "It is a salary advance loan designed for some State and Federal civil servants."
    },

    {
        question: 'How much can I borrow as a civil servant?',
        answer: "Minimun of N50,000.00 depending on your net pay"
    },
    {
        question: 'Can I generate and share my transaction receipt on the QuickFund App ?',
        answer: "Yes, you can generate, download and share transaction receipt.We also autosave every transaction so that you can also redownload any other time."
    },
    {
        question: 'Can I generate and download my statement of account on the QuickFund App ?',
        answer: "you can request an account statement for a specific time period.",
    },
    {
        question: 'How can I contact your bank if I need help ?',
        answer: "You can speak with our customer service representative on 09090512482, or send an email to support@quickfund.com",
    },
    {
        question: 'Where is the office of QuickFund Microfinance Bank ?',
        answer: "20 Channels Drive, OPIC Estate, Isheri, Ogun State."
    },
]


const FAQSection = () => {

    const [activeTab, setActiveTab] = useState("general");

    return (
        <>
            <div className="faq-section py-5 d-none d-lg-block">
                <div className="text-center pb-5">
                    <h2 className="font-primary fw-bolder">Frequently Asked Questions</h2>
                    <div className="d-flex flex-row justify-content-center mt-4">
                        <div onClick={() => setActiveTab("general")} className={`fs-6 me-5 ${activeTab == "general" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>
                            <div>
                                General
                            </div>
                            {activeTab == "general" && <div className="">
                                <svg width="15" height="10" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_4815_485)">
                                        <path d="M12.8296 0.479606C12.6707 0.248368 12.3293 0.248369 12.1704 0.479607L2.10534 15.1234C1.92292 15.3888 2.11293 15.75 2.43498 15.75L22.565 15.75C22.8871 15.75 23.0771 15.3888 22.8947 15.1234L12.8296 0.479606Z" fill="#520101" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_4815_485" x="0.0341797" y="0.306641" width="24.9316" height="19.4434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_485" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4815_485" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>}
                        </div>
                        <div onClick={() => setActiveTab("instant")} className={`fs-6 me-5 ${activeTab == "instant" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>
                            Instant Loan
                            {activeTab != "general" && <div className="">
                                <svg width="15" height="10" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_4815_485)">
                                        <path d="M12.8296 0.479606C12.6707 0.248368 12.3293 0.248369 12.1704 0.479607L2.10534 15.1234C1.92292 15.3888 2.11293 15.75 2.43498 15.75L22.565 15.75C22.8871 15.75 23.0771 15.3888 22.8947 15.1234L12.8296 0.479606Z" fill="#520101" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_4815_485" x="0.0341797" y="0.306641" width="24.9316" height="19.4434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="2" />
                                            <feGaussianBlur stdDeviation="1" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4815_485" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4815_485" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>}
                        </div>

                    </div>
                </div>

                <div className="mt-5 px-5">
                    <div className="px-5">
                        {
                            activeTab == "general" ? faqs.map((faq, index) => <CollapseCard
                                className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                title={faq.question}
                                response={faq.answer}
                                faqId={"faq" + index}
                            />) : <>
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"How can I apply for a QuickFund instant loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Trough the Unique lenders page.
                                                    Click https://www.quickteller.com/lender/quickfund to apply now
                                                </li>
                                                <ul>
                                                    <li> Login to the portal</li>
                                                    <li>   Click the request loan now button.</li>
                                                    <li> Click the preferred amount button.</li>
                                                    <li>Click and accept the available offer button.</li>
                                                </ul>
                                                <li>
                                                    Trough the Quickteller portal
                                                </li>
                                                <ul>
                                                    <li> Go to the QuickTeller website. https://www.quickteller.com</li>
                                                    <li> Login to the QuickTeller Portal</li>
                                                    <li> Search for the loan section and click the button to access the loan page</li>
                                                    <li> Click the preferred amount</li>
                                                    <li> Click the available offer.</li>
                                                    <li> Input your card details</li>
                                                    <li> Accept the available amount</li>
                                                </ul>
                                                <li>
                                                    Trough the UBA mobile app
                                                </li>
                                                <ul>
                                                    <li> Search for the loan page on the UBA mobile app and click apply to see eligible offers
                                                    </li>
                                                </ul>
                                                <li>
                                                    Through the QuickTeller USSD channel
                                                </li>
                                                <ul>
                                                    <li> Click the link *723*6# and follow the prompt
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq1"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"How do I know if I am eligible to request a loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    You may apply for our loan on any instant loan platform to confirm your eligibility.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq2"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"How can repay my loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Through your unique virtual account.
                                                </li>
                                                <ul>
                                                    <li>
                                                        A unique virtual account is a temporary account we assign to each customer to ease repayment, see below for more details.
                                                        <div className="mt-1">
                                                            To repay your loan via your unique virtual account, simply transfer the due loan amount to the virtual account and we do the rest.
                                                        </div>
                                                    </li>
                                                </ul>
                                                <li>
                                                    Trough the Quickteller payment portal
                                                </li>
                                                <ul>
                                                    <li> The customer login to the QuickTeller portal.</li>
                                                    <li> The customer searches for the loan section and clicks the loan button.</li>
                                                    <li> The customer clicks the repay loan button to pay the loan.</li>
                                                </ul>
                                                <li>
                                                    Through the QuickFund unique lenders link
                                                </li>
                                                <ul>
                                                    <li> The customer login through the unique lenders link +</li>
                                                    <li> The customer clicks the repay button to pay the loan.</li>
                                                </ul>
                                                <li>
                                                    Through the channel the customer got the loan from e.g. UBA
                                                </li>
                                                <ul>
                                                    <li> The customer login to the channel that the loan was disbursed</li>
                                                    <li> The customer goes to the loan section and clicks repayment.</li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq3"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"What is a virtual account?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    A virtual account is a temporary, fast and easy medium of payment. It is created when a customer applies for a loan and it reduces the possibility that the customer experiences issues with verification or reversal while repaying their loan.
                                                    <span className="fw-bold">  NOTE</span> - Your virtual account will always carry your name, always check before transferring that the account name is yours.
                                                </li>

                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq4"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"How can I increase my offer amount?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    By maintaining a good credit – Your behaviour with loans is tracked across lending companies and banks, for this reason defaulting with one lender can affect your chances of getting loans from other lenders.
                                                </li>
                                                <li>
                                                    By not defaulting when you take a loan on our channel – Timely repayment by QuickFund customers means they can access even higher offers and maintain a good credit score.
                                                </li>
                                                <li>
                                                    By not defaulting a loan with other lenders – For customers who switch to QuickFund, good repayment behaviour with other lenders will improve your credit rating and improve the loan offers you get from us.
                                                </li>

                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq5"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"What happens if I default on my loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Per regulation, late repayment means the customer will be reported to the Credit Bureau and will reduce their chances of getting loans from another lender.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq6"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"Why do I need a virtual account?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    The need for a virtual account is to ensure that the loan repayment process is smooth, secure and fast without the need for a customer to verify their payment.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq7"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"Where can I get my virtual account from?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    A virtual account is automatically created for you at the point of taking a loan, however, if you observe you cannot find yours, see below
                                                </li>
                                                <li>
                                                    Call, text on Whatsapp or Send SMS to 09137000163 or 0904811180 for enquiries, complaints or for us to provide you with a virtual account.
                                                </li>
                                                <li>
                                                    Send an email to support@quickfundmfb.com and our support team will get back to you quickly.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq8"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"Can I pay my loan in installments?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Yes, you can make your loan payment in installments, but you must pay within the agreed repayment period
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq9"}
                                />
                                <CollapseCard
                                    className="bg-teal-300 py-2 px-4 rounded-4 mb-4"
                                    title={"How do I contact you if I have complaints or questions?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Please call 09137000163 or 09048111806 for any assistance, or send a mail to support@quickfundmfb.com
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq10"}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="faq-section py-5 d-block d-lg-none">
                <div className="text-center">
                    <h1 className="text-dark-teal display-5 px-4 font-primary fw-bolder">Frequently Asked Questions</h1>
                    <div className="d-flex flex-row justify-content-center mt-4">
                        <div onClick={() => setActiveTab("general")} className={`fs-6 me-5 ${activeTab == "general" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>General</div>
                        <div onClick={() => setActiveTab("instant")} className={`fs-6 me-5 ${activeTab == "instant" ? "text-dark-teal fw-bolder" : "fw-bold"}`} style={{ cursor: "pointer" }}>Instant Loan</div>
                    </div>
                </div>

                <div className="mt-5 px-1">
                    <div className="px-2">

                        {
                            activeTab == "general" ? faqs.map((faq, index) => <CollapseCard
                                className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                title={faq.question}
                                response={faq.answer}
                                faqId={"faq" + index}
                            />) : <>
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"How can I apply for a QuickFund instant loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Trough the Unique lenders page.
                                                    Click https://www.quickteller.com/lender/quickfund to apply now
                                                </li>
                                                <ul>
                                                    <li> Login to the portal</li>
                                                    <li>   Click the request loan now button.</li>
                                                    <li> Click the preferred amount button.</li>
                                                    <li>Click and accept the available offer button.</li>
                                                </ul>
                                                <li>
                                                    Trough the Quickteller portal
                                                </li>
                                                <ul>
                                                    <li> Go to the QuickTeller website. https://www.quickteller.com</li>
                                                    <li> Login to the QuickTeller Portal</li>
                                                    <li> Search for the loan section and click the button to access the loan page</li>
                                                    <li> Click the preferred amount</li>
                                                    <li> Click the available offer.</li>
                                                    <li> Input your card details</li>
                                                    <li> Accept the available amount</li>
                                                </ul>
                                                <li>
                                                    Trough the UBA mobile app
                                                </li>
                                                <ul>
                                                    <li> Search for the loan page on the UBA mobile app and click apply to see eligible offers
                                                    </li>
                                                </ul>
                                                <li>
                                                    Through the QuickTeller USSD channel
                                                </li>
                                                <ul>
                                                    <li> Click the link *723*6# and follow the prompt
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq1"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"How do I know if I am eligible to request a loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    You may apply for our loan on any instant loan platform to confirm your eligibility.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq2"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"How can repay my loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Through your unique virtual account.
                                                </li>
                                                <ul>
                                                    <li>
                                                        A unique virtual account is a temporary account we assign to each customer to ease repayment, see below for more details.
                                                        <div className="mt-1">
                                                            To repay your loan via your unique virtual account, simply transfer the due loan amount to the virtual account and we do the rest.
                                                        </div>
                                                    </li>
                                                </ul>
                                                <li>
                                                    Trough the Quickteller payment portal
                                                </li>
                                                <ul>
                                                    <li> The customer login to the QuickTeller portal.</li>
                                                    <li> The customer searches for the loan section and clicks the loan button.</li>
                                                    <li> The customer clicks the repay loan button to pay the loan.</li>
                                                </ul>
                                                <li>
                                                    Through the QuickFund unique lenders link
                                                </li>
                                                <ul>
                                                    <li> The customer login through the unique lenders link +</li>
                                                    <li> The customer clicks the repay button to pay the loan.</li>
                                                </ul>
                                                <li>
                                                    Through the channel the customer got the loan from e.g. UBA
                                                </li>
                                                <ul>
                                                    <li> The customer login to the channel that the loan was disbursed</li>
                                                    <li> The customer goes to the loan section and clicks repayment.</li>
                                                </ul>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq3"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"What is a virtual account?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    A virtual account is a temporary, fast and easy medium of payment. It is created when a customer applies for a loan and it reduces the possibility that the customer experiences issues with verification or reversal while repaying their loan.
                                                    <span className="fw-bold">  NOTE</span> - Your virtual account will always carry your name, always check before transferring that the account name is yours.
                                                </li>

                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq4"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"How can I increase my offer amount?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    By maintaining a good credit – Your behaviour with loans is tracked across lending companies and banks, for this reason defaulting with one lender can affect your chances of getting loans from other lenders.
                                                </li>
                                                <li>
                                                    By not defaulting when you take a loan on our channel – Timely repayment by QuickFund customers means they can access even higher offers and maintain a good credit score.
                                                </li>
                                                <li>
                                                    By not defaulting a loan with other lenders – For customers who switch to QuickFund, good repayment behaviour with other lenders will improve your credit rating and improve the loan offers you get from us.
                                                </li>

                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq5"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"What happens if I default on my loan?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Per regulation, late repayment means the customer will be reported to the Credit Bureau and will reduce their chances of getting loans from another lender.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq6"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"Why do I need a virtual account?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    The need for a virtual account is to ensure that the loan repayment process is smooth, secure and fast without the need for a customer to verify their payment.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq7"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"Where can I get my virtual account from?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    A virtual account is automatically created for you at the point of taking a loan, however, if you observe you cannot find yours, see below
                                                </li>
                                                <li>
                                                    Call, text on Whatsapp or Send SMS to 09137000163 or 0904811180 for enquiries, complaints or for us to provide you with a virtual account.
                                                </li>
                                                <li>
                                                    Send an email to support@quickfundmfb.com and our support team will get back to you quickly.
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq8"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"Can I pay my loan in installments?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Yes, you can make your loan payment in installments, but you must pay within the agreed repayment period
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq9"}
                                />
                                <CollapseCard
                                    className="bg-secondary bg-opacity-25 py-2 px-4 mb-2 rounded-4"
                                    title={"How do I contact you if I have complaints or questions?"}
                                    response={
                                        <div>
                                            <ul>
                                                <li>
                                                    Please call 09137000163 or 09048111806 for any assistance, or send a mail to support@quickfundmfb.com
                                                </li>
                                            </ul>
                                        </div>
                                    }

                                    faqId={"faq10"}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const FAQ = () => {
    return (
        <div className="loan-and-overdraft">

            <FAQSection />
            <SocialMediaSection useDark />
            <NewsLetterSection />
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            <NoteSection />
            <Footer />
        </div>
    );
}

export default FAQ;