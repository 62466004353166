const HowItWorksSection = () => {
    return (
        <div className="how-it-works p-2">
            <h4 className="text-dark-teal font-primary fw-bold text-center">
                How it works
            </h4>
            <div className="d-flex flex-column flex-lg-row justify-content-center">
                <div className="m-2 col-lg-3 p-3 border border-2 border-dark bg-secondary bg-opacity-25 rounded-3">
                    <h5 className="text-dark-teal mb-2 font-primary text-dark-primary fw-bold">Apply</h5>
                    <p>
                        Choose your loan amount & tenure
                        then share some information
                        about yourself and upload some basic documents
                    </p>
                </div>
                <div className="m-2 col-lg-3 p-3 border border-2 border-dark bg-secondary bg-opacity-25 rounded-3">
                    <h5 className="text-dark-teal mb-2 font-primary text-dark-primary fw-bold">We Verify</h5>
                    <p>
                        We verify the information that you've
                        provided to us in the application forms
                    </p>
                </div>
                <div className="m-2 col-lg-3 p-3 border border-2 border-dark bg-secondary bg-opacity-25 rounded-3">
                    <h5 className="text-dark-teal mb-2 font-primary text-dark-primary fw-bold">Disbursement</h5>
                    <p>
                        We disburse the money in your bank account
                        within 24 hours, if your loan is approved
                    </p>
                </div>
            </div>
        </div>
    )
}


export default HowItWorksSection;