import React from "react";
import { AboutLandingImg, IphoneMock, VisionManImg, OurMissionImg, OurTeamImg, TopnotchImg } from "../../Assets/Images";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import OpenAccountSection from "../Components/OpenAccountSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
import "./style.scss"

declare interface DetailCardProps {
    heading: string;
    thumbnail: string;
    detail: string;
}


const DetailCard: React.FC<DetailCardProps> = ({ heading, thumbnail, detail }) => {
    return (
        <>
            {/* desktop */}
            <div className="vision-card rounded-3 py-4 px-lg-2 px-1 pe-lg-4 d-none d-lg-flex">
                <div className="me-4">
                    <img src={thumbnail} alt="" width={85} className="img detail-img d-none d-md-block" />

                </div>
                <div>
                    <h5 className="text-dark-teal mb-2">{heading}</h5>
                    <p className="d-none d-md-block fs-6">
                        <small>
                            {detail}
                        </small>
                    </p>
                </div>
            </div>

            <div className="vision-card-sm d-flex d-lg-none rounded-3 py-4 px-lg-2 px-2 pe-lg-4">
                <div className="me-4 align-self-center">
                    <img src={thumbnail} alt="" width={78} className="img detail-img d-bock d-md-none" />

                </div>
                <div>
                    <h5 className="text-dark-teal mb-2">{heading}</h5>

                    <p className="d-block d-md-none fw-normal">
                        {
                            detail
                        }
                    </p>
                </div>
            </div>
        </>
    );
}

const DetailSection = () => {
    return (
        <div className="detail-section px-3 px-lg-5">
            <div className="row justify-content-around mx-auto">
                <div className="col-lg-5 col-12 mt-3">
                    <DetailCard heading="Our Vision and Values" thumbnail={VisionManImg} detail={"To be the preferred consumer finance partner for our customers. "} />
                </div>
                <div className="col-lg-5 col-12 mt-3">
                    <DetailCard heading="Topnotch Customer Support" thumbnail={TopnotchImg} detail={"We are a customer focused bank built on pillars of innovation and service excellence, delivering superior financial services that empower the communities we serve."} />
                </div>
                <div className="col-lg-5 col-12 mt-3">
                    <DetailCard heading="Our Mission Statement" thumbnail={OurMissionImg} detail={"We are a customer focused bank built on pillars of innovation and service excellence, delivering superior financial services that empower the communities we serve."} />
                </div>
                <div className="col-lg-5 col-12 mt-3">
                    <DetailCard heading="Join Our Team" thumbnail={OurTeamImg} detail={"Start your career with QuickFund today. Check our available opening and apply today."} />
                </div>
            </div>

        </div>
    )
}

const AboutLanding = () => {
    return (
        <div className="about-landing px-lg-4 px-2 mt-5">
            <div className="row py-4 py-lg-6 d-lg-flex flex-column-reverse flex-lg-row justify-content-around px-lg-5 mx-auto">
                <div className="col-12 col-lg-5  align-self-center mb-4">
                    <h2 className="fw-bolder font-primary">
                        Who Are We?
                    </h2>
                    <p className="detail mt-4 fs-5" style={{ textAlign: "justify", lineHeight: 1.8 }}>
                        QuickFund Microfinance Bank was incorporated in December 2017 under the Companies and Allied Matters Acts of 1990 and was licensed by the Central Bank of Nigeria (CBN) in July 2018. The Bank commenced business in September 2018 to carry out the business of Micro financing.  Our business location is 20, Channels Drive OPIC Estate Isheri Ogun State Nigeria. With a shareholders’ fund in excess of N20m and recently increased to N100m in May 2019, the bank is poised to empower the active poor Nigerians and become the leading Microfinance Bank in Nigeria.

                    </p>


                </div>
                <div className="col-12 col-lg-6">
                    <img src={AboutLandingImg} alt="" className="img img-fluid" />
                </div>
            </div>
        </div>
    )
}


const About = () => {
    return (
        <div className="about">
            <AboutLanding />
            <DetailSection />
            <OpenAccountSection />
            <NewsLetterSection />
            <NoteSection />
            <Footer />
        </div>
    );
}

export default About;