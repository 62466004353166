import { DebitCardIcon, InstantApprovalIcon, ShieldIcon, USSDIcon2 } from "../../../Assets/Icons"
import { ConversationImg } from "../../../Assets/Images"
import BenefitCard from "../../../Components/BenefitCard"
import { Container, ContainerFluid } from "../../../Components/Container"

const BenefitSection = () => {
    return (
        <ContainerFluid className="px-lg-5 px-4">
            <div className="benefit-section px-2">

                {/* desktop */}
                <div className="d-none d-lg-block">
                    <h1 className="fw-bolder font-primary m-0 p-0 mt-4 fs-2"> Benefits of Banking with  </h1>
                    <span className="font-secondary fs-2 h1 fw-bolder text-red mb-1"> QuickFund </span>
                </div>

                {/* mobile */}
                <div className="d-block d-lg-none">
                    <div className="text-center">
                        <h5 className="font-primary fw-bold fs-4">Benefits of Banking with <br></br> <span className="font-secondary text-red fw-bolder">QuickFund</span></h5>
                    </div>
                </div>

                <div className="row py-lg-4 mt-1">
                    <div className="col-lg-6 col-12 flex-fill">
                        <div className="row">
                            <div className="col-lg-6 col-12 mt-3 px-lg-4">
                                <BenefitCard
                                    title="Security and Safety"
                                    icon={ShieldIcon}
                                    borderColor="#520101"
                                    description="We are committed to the protection of your personal data. 
                                                 Maintaining ultimate data safety when collecting, 
                                                 sharing, or using data about you is a top priority at 
                                                 QuickFund. We take appropriate measures to safeguard 
                                                your personal information, hence, prevent unauthorized access."/>
                            </div>
                            <div className="col-lg-6 col-12 mt-3 px-lg-4">
                                <BenefitCard
                                    title="USSD Banking"
                                    icon={USSDIcon2}
                                    borderColor="#535357"
                                    description="Bank on the go using USSD. We have USSD code for offline transactions. 
                                    It is convenient and you get instant approval once a transaction is made.
                                    "/>

                            </div>
                            <div className="col-lg-6 col-12 mt-5 px-lg-4">
                                <BenefitCard
                                    title="Instant Approvals"
                                    icon={InstantApprovalIcon}
                                    borderColor="#A3DA73"
                                    description="We don’t delay! At QuickFund, we grant quick approvals to loan application once you provide us with every necessary information and requirements." />
                            </div>
                            <div className="col-lg-6 col-12 mt-5 px-lg-4">
                                <BenefitCard
                                    title="Debit Cards"
                                    icon={DebitCardIcon}
                                    borderColor="#F9703C"
                                    description="Get a debit card that is widely accepted in major ATMs, POS and 
                                    other Retail Stores Nationwide - that guarantees 
                                    you optimum access and control of funds in your account across the country. "/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 mt-3 d-none d-lg-block">
                        <img src={ConversationImg} className="img-fluid" />
                    </div>
                </div>
            </div>
        </ContainerFluid>

    )
}

export default BenefitSection;