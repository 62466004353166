import { Link } from "react-router-dom";
import { AppleIcon } from "../../../Assets/Icons";
import "./style.scss"

const AppStoreButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (

        <a href={"https://apps.apple.com/us/app/quickfund-mfb-mobile/id1627263878"} target="_blank" rel="noopener noreferrer" className={`app-btn  bg-white btn border-2 me-lg-3  ${props.className}`}>
            <img width={24} height={24} src={AppleIcon} style={{ position: "relative", left: -5 }} />
            <span className="small" style={{ position: "relative", left: -7 }}> Download for IOS </span>
            {/* <span style={{ visibility: "hidden", left: -7 }}> Download for IOS </span> */}
        </a>
    );
}

export default AppStoreButton;