import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import Home from './Pages/Home';
import About from './Pages/About';
import BillsAndMore from './Pages/BillsAndMore';
import Contact from './Pages/Contact';
import LoanAndOverdraft from './Pages/LoanAndOverdraft';
import FAQ from './Pages/FAQ';
import SalaryLoan from './Pages/SalaryLoan';
import SavingsAndTransfer from './Pages/SavingsAndTransfers';
import TermsAndPolicy from './Pages/PrivacyPolicy';
import Article from './Pages/Article';
import TermsAndCondition from './Pages/TermsAndCondition';
import OracleLoan from './Pages/Oracle-Loan';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/bills-and-more" element={<BillsAndMore />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/loan-and-overdraft" element={<LoanAndOverdraft />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="#" element={<OracleLoan />} />
          <Route path="/salary-loan" element={<SalaryLoan />} />
          <Route path="/savings-and-transfer" element={<SavingsAndTransfer />} />
          <Route path="/privacy-policy" element={<TermsAndPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />

          <Route path="/articles" element={<Article />} />
        </Route>
      </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
