import "./style.scss"
import { ClientImg } from "../../Assets/Images"


declare interface Props {
    clientName: string;
    clientLocation: string;
    clientImg?: string;
    clientComment: string;
}

const TestmonialCard: React.FC<Props> = (props) => {
    return (
        <div className="bg-white testimonial-card position-relative rounded-3 shadow p-3">
            <div className="client-img relative mb-5 d-flex justify-content-center">
                <div className="h-[55px] w-[55px] profile rounded-full mx-auto  fw-bolder fs-5" style={{ width: 55, height: 55, borderRadius: "100%", margin: "auto" }}>
                    {props.clientName.split(" ")[0][0] + props.clientName.split(" ")[props.clientName.split(" ").length - 1][0]}
                </div>
                {/* <img src={ClientImg} className="img" height={55} alt="client image" /> */}
            </div>
            <div className="client-name text-center">
                <h5 className="m-0 p-0 fw-bolder fs-6">{props.clientName}</h5>
                <h6 className="text-secondary" style={{ fontSize: "11px" }}>
                    {props.clientLocation}
                </h6>
            </div>
            <div className="client-comment mt-4 text-center" >
                <p className="description">
                    {
                        props.clientComment
                    }
                </p>
            </div>
        </div>
    )
}

export default TestmonialCard;