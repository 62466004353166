import { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CashBagIcon, CashHandIcon, OfflineBankingIcon, QuickFundIcon, USSDIcon, WalletIcon } from "../../Assets/Icons"
import "./style.scss"

const NavbarIcon: React.FC = () => {
	return (
		<a className="navbar-brand" href="/">
			<img className="img navbar-logo d-lg-none d-inline-block" width="120px" src={QuickFundIcon} alt="quickfund logo" />
			<img className="img navbar-logo d-none d-lg-inline-block" width="160px" src={QuickFundIcon} alt="quickfund logo" />
		</a>
	)
}

declare interface INavLink {
	title: string;
	href: string;
	className?: string;
	target?: string;
}

declare interface NavbarLinkProps {
	navLink: INavLink;
	dropdown: boolean;
	dropdownList?: INavLink[]

}

const NavbarLink: React.FC<NavbarLinkProps> = (props) => {

	const navLink = useRef(HTMLAnchorElement.prototype);
	const navLinkDropDown = useRef(HTMLUListElement.prototype);

	const handleMouseHover = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLUListElement, MouseEvent>) => {
		if (e.type == "mouseenter") {
			navLink.current.classList.add("show");
			navLinkDropDown.current.classList.add("show");
			return;
		}

		navLink.current.classList.remove("show");
		navLinkDropDown.current.classList.remove("show");
	}

	return (
		<li className={`nav-item ${props.dropdown ? "dropdown" : ""}`}>
			{props.dropdown ?
				<Link ref={navLink} onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover} className={`nav-link ${props.dropdown ? "dropdown-toggle position-relative" : ""} ${props.navLink.className} text-black`} id="navbarDropdown" aria-current="page" to={props.navLink.href} role="button" data-bs-toggle="dropdown" aria-expanded="false">
					{props.navLink.title}
				</Link> :
				<Link className={`nav-link text-black`} to={props.navLink.href}>
					{props.navLink.title}
				</Link>
			}
			{
				props.dropdown &&
				<ul ref={navLinkDropDown} onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover} className="dropdown-menu" aria-labelledby="navbarDropdown">
					{
						props.dropdownList && props.dropdownList.map((link, index) => <li key={index}>
							<a className={`dropdown-item ${link.className}`} href={link.href}>
								{link.title}
							</a>
						</li>)
					}
				</ul>
			}
		</li >
	)
}

declare interface NavbarMenuProps {
	navbarLinks: NavbarLinkProps[]
}

const NavbarMenu: React.FC<NavbarMenuProps> = (props) => {
	return (
		<div className="collapse justify-content-center navbar-collapse">
			<ul className="navbar-nav gap-3">
				{
					props.navbarLinks.map((link, index) => <NavbarLink key={index} navLink={link.navLink} dropdown={link.dropdown} dropdownList={link.dropdownList} />)
				}
			</ul>
		</div>
	)
}


const navLinks: NavbarLinkProps[] = [
	{
		navLink: { title: "ABOUT US", href: "about" },
		dropdown: false
	},
	{
		navLink: { title: "OPEN ACCOUNT", href: "open-account" },
		dropdown: true,
		dropdownList: [{ title: "Open a Savings Account, Current Account or Fixed Deposit, with competitive interest rate.", href: "https://ibank.mybankone.com/quickfundmfb/Account/OpenAccount" }, { title: "Read more", href: "https://ibank.mybankone.com/quickfundmfb/Account/OpenAccount", className: "text-primary" }]
	},
	{
		navLink: { title: "CONTACT US", href: "contact" },
		dropdown: false
	},
	{
		navLink: { title: "HELP", href: "help" },
		dropdown: true,
		dropdownList: [{ title: "FAQs", href: "faq" }]
	}
]

const Navbar: React.FC = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="navbar navbar-expand-lg shadow-sm fixed-top bg-white">
				<div className="container-fluid px-lg-5 px-4">
					<NavbarIcon />
					<NavbarMenu navbarLinks={navLinks} />
					<div className="">
						<a href="https://www.quickteller.com/lender/quickfund" className="btn py-2 btn-outine-dark me-3 d-none d-lg-inline px-3 fw-bold" style={{ border: "2px solid", borderRadius: "10px", fontSize: "12px" }}> <span className=""> GET INSTANT LOAN </span> </a>
						<a href="https://ibank.mybankone.com/quickfundmfb" className="btn banking-btn py-2 btn-dark fw-light me-5 d-none d-lg-inline px-3" style={{ borderRadius: "10px", fontSize: "12px" }}> <span > ONLINE BANKING </span> </a>
						<button className="btn shadow-none" data-bs-toggle="offcanvas" data-bs-target="#menu-offcanvas" aria-controls="menu-offcanvas">
							<svg width="17" height="16" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2 2H23" stroke="#F6011B" strokeOpacity="0.87" strokeWidth="4" strokeLinecap="round" />
								<path d="M7 12H22" stroke="#F6011B" strokeOpacity="0.87" strokeWidth="4" strokeLinecap="round" />
								<path d="M2 22H23" stroke="#F6011B" strokeOpacity="0.87" strokeWidth="4" strokeLinecap="round" />
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div className="py-lg-3 mb-lg-5 py-3 mb-2"></div>
			<div className="offcanvas offcanvas-end" tabIndex={1} id="menu-offcanvas" aria-labelledby="offcanvasExampleLabel">
				<div className="position-relative">
					{/* desktop */}
					<div className="position-absolute d-flex h-100" style={{ left: "-38px" }}>
						<button data-bs-dismiss="offcanvas" aria-label="Close" type="button" className="btn p-0 shadow-none">
							<svg className="align-self-center" width="25" height="25" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.4998 32.0832C25.554 32.0832 32.0832 25.554 32.0832 17.4998C32.0832 9.44568 25.554 2.9165 17.4998 2.9165C9.44568 2.9165 2.9165 9.44568 2.9165 17.4998C2.9165 25.554 9.44568 32.0832 17.4998 32.0832Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M21.875 13.125L13.125 21.875" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M13.125 13.125L21.875 21.875" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</button>
					</div>

					{/* mobile */}
					<div className="offcanvas-header mt-lg-5 d-flex d-lg-none justify-space-between">
						<NavbarIcon />
						<button type="button" className="btn-close text-reset align-self-center" data-bs-dismiss="offcanvas" aria-label="Close"></button>
					</div>

					{/* mobile */}
					<div className="mt-lg-5 px-3 d-flex d-lg-none d-flex justify-content-between" style={{ display: "flex", padding: "0 10px" }}>
						<a href="https://www.quickteller.com/lender/quickfund" className="btn btn-outine-dark me-3 d-lg-inline px-2 fw-bold flex-1" style={{ border: "2px solid", borderRadius: "10px", fontFamily: "Poppins", fontSize: "13px", flex: "1", paddingTop: "0.8rem", paddingBottom: "0.8rem" }}> <span > GET INSTANT LOAN </span> </a>
						<a href="https://ibank.mybankone.com/quickfundmfb" className=" btn btn-dark fw-light d-lg-inline-block px-2 text-light flex-1 me-2" style={{ borderRadius: "10px", fontFamily: "Poppins", fontSize: "13px", flex: "1", paddingTop: "0.8rem", paddingBottom: "0.8rem" }}> <span className=""> ONLINE BANKING</span> </a>
					</div>

					{/* desktop */}
					<div className="mt-lg-5 px-5 d-lg-block d-none">
						<h5 className="offcanvas-title text-end text-red font-primary fw-bold" id="offcanvasExampleLabel">QUICK LINKS</h5>
					</div>


					{/* mobile */}
					<div className="offcanvas-body d-lg-none d-block mt-3">
						<ul className="nav flex-column">
							<h5 className="fw-normal">PRODUCTS</h5>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("#", { replace: true })} className={`nav-link text-black ps-0 fw-bold`} to={"*"}>
										<img src={CashBagIcon} className="me-1" alt="" width="16" />
										Savings and Deposits
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/loan-and-overdraft", { replace: true })} className={`nav-link text-black ps-0 fw-bold`} to={"/loan-and-overdraft"}>
										<img src={CashHandIcon} className="me-1" alt="" width="16" />
										Loans and Overdrafts
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/bills-and-more", { replace: true })} className={`nav-link text-black ps-0 fw-bold`} to={"/bills-and-more"}>
										<img src={WalletIcon} className="me-1" alt="" width="16" />
										Bills and More
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/", { replace: true })} className={`nav-link text-black ps-0 fw-bold`} to={"#"}>
										<img src={OfflineBankingIcon} className="me-1" alt="" width="16" />
										Offline Banking (*614*448#)
									</Link>
								</h5>
							</li>

							<div className="mt-5">
								<h5 className="fw-normal">COMPANY</h5>
							</div>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/about", { replace: true })} className={`nav-link text-black ps-0`} to={"/about"}>
										About Us
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/contact", { replace: true })} className={`nav-link text-black ps-0`} to={"/contact"}>
										Contact Us
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/", { replace: true })} className={`nav-link text-black ps-0`} to={"#"}>
										Join a Cooporative
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/articles", { replace: true })} className={`nav-link text-black ps-0`} to={"/article"}>
										Articles
									</Link>
								</h5>
							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" onClick={() => navigate("/faq", { replace: true })} className={`nav-link text-black ps-0`} to={"/faq"}>
										FAQs
									</Link>
								</h5>
							</li>
						</ul>
						<div className="my-4">
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.0012 2.50293C8.09869 2.50293 2.50244 8.09918 2.50244 15.0017C2.50244 21.2392 7.07244 26.4092 13.0487 27.3504V18.6154H9.87369V15.0017H13.0487V12.2479C13.0487 9.11293 14.9149 7.38418 17.7687 7.38418C19.1362 7.38418 20.5687 7.62793 20.5687 7.62793V10.7017H18.9887C17.4387 10.7017 16.9537 11.6667 16.9537 12.6554V14.9992H20.4174L19.8637 18.6129H16.9537V27.3479C22.9299 26.4117 27.4999 21.2404 27.4999 15.0017C27.4999 8.09918 21.9037 2.50293 15.0012 2.50293Z" fill="#0A0A0A" />
									<path d="M15.0012 2.50293C8.09869 2.50293 2.50244 8.09918 2.50244 15.0017C2.50244 21.2392 7.07244 26.4092 13.0487 27.3504V18.6154H9.87369V15.0017H13.0487V12.2479C13.0487 9.11293 14.9149 7.38418 17.7687 7.38418C19.1362 7.38418 20.5687 7.62793 20.5687 7.62793V10.7017H18.9887C17.4387 10.7017 16.9537 11.6667 16.9537 12.6554V14.9992H20.4174L19.8637 18.6129H16.9537V27.3479C22.9299 26.4117 27.4999 21.2404 27.4999 15.0017C27.4999 8.09918 21.9037 2.50293 15.0012 2.50293Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.9999 11.0946C12.8495 11.0946 11.0946 12.8495 11.0946 14.9999C11.0946 17.1503 12.8495 18.9052 14.9999 18.9052C17.1503 18.9052 18.9052 17.1503 18.9052 14.9999C18.9052 12.8495 17.1503 11.0946 14.9999 11.0946ZM26.7128 14.9999C26.7128 13.3827 26.7274 11.7802 26.6366 10.1659C26.5458 8.29091 26.1181 6.62685 24.747 5.25575C23.3729 3.88173 21.7118 3.45693 19.8368 3.36611C18.2196 3.27529 16.6171 3.28993 15.0028 3.28993C13.3856 3.28993 11.7831 3.27529 10.1688 3.36611C8.29384 3.45693 6.62978 3.88466 5.25868 5.25575C3.88466 6.62978 3.45986 8.29091 3.36904 10.1659C3.27822 11.7831 3.29286 13.3856 3.29286 14.9999C3.29286 16.6142 3.27822 18.2196 3.36904 19.8339C3.45986 21.7089 3.88759 23.3729 5.25868 24.744C6.63271 26.1181 8.29384 26.5429 10.1688 26.6337C11.786 26.7245 13.3886 26.7099 15.0028 26.7099C16.62 26.7099 18.2225 26.7245 19.8368 26.6337C21.7118 26.5429 23.3759 26.1151 24.747 24.744C26.121 23.37 26.5458 21.7089 26.6366 19.8339C26.7304 18.2196 26.7128 16.6171 26.7128 14.9999ZM14.9999 21.0087C11.6747 21.0087 8.9911 18.3251 8.9911 14.9999C8.9911 11.6747 11.6747 8.99111 14.9999 8.99111C18.3251 8.99111 21.0087 11.6747 21.0087 14.9999C21.0087 18.3251 18.3251 21.0087 14.9999 21.0087ZM21.2548 10.1483C20.4784 10.1483 19.8515 9.52138 19.8515 8.74501C19.8515 7.96865 20.4784 7.34169 21.2548 7.34169C22.0311 7.34169 22.6581 7.96865 22.6581 8.74501C22.6583 8.92936 22.6222 9.11195 22.5517 9.28231C22.4813 9.45268 22.3779 9.60747 22.2476 9.73783C22.1172 9.86818 21.9624 9.97154 21.7921 10.042C21.6217 10.1124 21.4391 10.1486 21.2548 10.1483Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15 1.875C7.75195 1.875 1.875 7.75195 1.875 15C1.875 22.248 7.75195 28.125 15 28.125C22.248 28.125 28.125 22.248 28.125 15C28.125 7.75195 22.248 1.875 15 1.875ZM21.3076 11.7686C21.3164 11.9062 21.3164 12.0498 21.3164 12.1904C21.3164 16.4912 18.041 21.4453 12.0557 21.4453C10.21 21.4453 8.49902 20.9092 7.05762 19.9863C7.32129 20.0156 7.57324 20.0273 7.84277 20.0273C9.36621 20.0273 10.7666 19.5117 11.8828 18.6387C10.4531 18.6094 9.25195 17.6719 8.8418 16.3828C9.34277 16.4561 9.79395 16.4561 10.3096 16.3242C9.57342 16.1747 8.91174 15.7749 8.43696 15.1927C7.96218 14.6106 7.70357 13.8821 7.70508 13.1309V13.0898C8.13574 13.333 8.64258 13.4824 9.17285 13.5029C8.72708 13.2058 8.3615 12.8034 8.10853 12.3311C7.85556 11.8589 7.72302 11.3316 7.72266 10.7959C7.72266 10.1895 7.88086 9.63574 8.16504 9.15527C8.98214 10.1611 10.0017 10.9838 11.1576 11.5698C12.3135 12.1558 13.5797 12.4921 14.874 12.5566C14.4141 10.3447 16.0664 8.55469 18.0527 8.55469C18.9902 8.55469 19.834 8.94727 20.4287 9.58008C21.1641 9.44238 21.8672 9.16699 22.4941 8.79785C22.251 9.55078 21.7412 10.1865 21.0645 10.5879C21.7207 10.5176 22.3535 10.3359 22.9395 10.0811C22.4971 10.7314 21.9434 11.3086 21.3076 11.7686Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.4046 14.7491L14.0356 13.1771C13.7416 13.0406 13.5001 13.1936 13.5001 13.5191V16.4801C13.5001 16.8056 13.7416 16.9586 14.0356 16.8221L17.4031 15.2501C17.6986 15.1121 17.6986 14.8871 17.4046 14.7491ZM15.0001 0.599609C7.0471 0.599609 0.600098 7.04661 0.600098 14.9996C0.600098 22.9526 7.0471 29.3996 15.0001 29.3996C22.9531 29.3996 29.4001 22.9526 29.4001 14.9996C29.4001 7.04661 22.9531 0.599609 15.0001 0.599609ZM15.0001 20.8496C7.6291 20.8496 7.5001 20.1851 7.5001 14.9996C7.5001 9.81411 7.6291 9.14961 15.0001 9.14961C22.3711 9.14961 22.5001 9.81411 22.5001 14.9996C22.5001 20.1851 22.3711 20.8496 15.0001 20.8496Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15 1.875C7.75195 1.875 1.875 7.75195 1.875 15C1.875 22.248 7.75195 28.125 15 28.125C22.248 28.125 28.125 22.248 28.125 15C28.125 7.75195 22.248 1.875 15 1.875ZM22.5 9.30762L21.3047 10.4531C21.1992 10.5322 21.1494 10.6611 21.1699 10.7871V19.2158C21.1494 19.3447 21.1992 19.4736 21.3047 19.5498L22.4766 20.6953V20.9502H16.5938V20.707L17.8037 19.5322C17.9238 19.4121 17.9238 19.377 17.9238 19.1982V12.3779L14.5547 20.9209H14.1006L10.1807 12.3779V18.1055C10.1455 18.3457 10.2305 18.5889 10.4004 18.7617L11.9766 20.6689V20.9238H7.5V20.6689L9.07617 18.7617C9.15922 18.6759 9.22102 18.5719 9.25663 18.4579C9.29224 18.344 9.30067 18.2233 9.28125 18.1055V11.4844C9.30176 11.2998 9.23144 11.1211 9.09082 10.9951L7.69043 9.30762V9.05273H12.041L15.3984 16.4209L18.3545 9.05859H22.5V9.30762Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-4">
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.0001 0.599609C7.0471 0.599609 0.600098 7.04661 0.600098 14.9996C0.600098 22.9526 7.0471 29.3996 15.0001 29.3996C22.9531 29.3996 29.4001 22.9526 29.4001 14.9996C29.4001 7.04661 22.9531 0.599609 15.0001 0.599609ZM11.4751 20.9681H8.5591V11.5841H11.4751V20.9681ZM9.9991 10.4321C9.0781 10.4321 8.4826 9.77961 8.4826 8.97261C8.4826 8.14911 9.0961 7.51611 10.0366 7.51611C10.9771 7.51611 11.5531 8.14911 11.5711 8.97261C11.5711 9.77961 10.9771 10.4321 9.9991 10.4321ZM22.1251 20.9681H19.2091V15.7676C19.2091 14.5571 18.7861 13.7351 17.7316 13.7351C16.9261 13.7351 16.4476 14.2916 16.2361 14.8271C16.1581 15.0176 16.1386 15.2876 16.1386 15.5561V20.9666H13.2211V14.5766C13.2211 13.4051 13.1836 12.4256 13.1446 11.5826H15.6781L15.8116 12.8861H15.8701C16.2541 12.2741 17.1946 11.3711 18.7681 11.3711C20.6866 11.3711 22.1251 12.6566 22.1251 15.4196V20.9681Z" fill="#0A0A0A" />
								</svg>

							</a>
						</div>
					</div>


					{/* desktop offcanvas */}
					<div className="offcanvas-body d-none d-lg-block mt-3 desktop-offcanvas">
						<ul className="nav flex-column text-end">
							<li className="nav-item">

								<a data-bs-dismiss="offcanvas" aria-label="Close" type="button" className={`nav-link text-black`} onClick={() => { window.location.href = "https://ibank.mybankone.com/quickfundmfb" }} href={"https://ibank.mybankone.com/quickfundmfb"}>
									Online Banking
									<svg className="ms-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.2796 5.72509C2.13253 5.87913 2.13194 6.12139 2.27826 6.27614L7.07417 11.3483C7.3224 11.6108 7.76393 11.4358 7.76481 11.0745L7.78944 0.95353C7.79032 0.59222 7.34965 0.415005 7.10014 0.67633L2.2796 5.72509Z" fill="#282828" />
									</svg>
								</a>

							</li>
							<li className="nav-item">
								<h5 className="font-primary fw-normal">
									<Link onClick={() => navigate("/about", { replace: true })} data-bs-dismiss="offcanvas" aria-label="Close" type="button" className={`nav-link text-black`} to={"/about"}>
										About Us
										<svg className="ms-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M2.2796 5.72509C2.13253 5.87913 2.13194 6.12139 2.27826 6.27614L7.07417 11.3483C7.3224 11.6108 7.76393 11.4358 7.76481 11.0745L7.78944 0.95353C7.79032 0.59222 7.34965 0.415005 7.10014 0.67633L2.2796 5.72509Z" fill="#282828" />
										</svg>
									</Link>
								</h5>
							</li>
							<li className="nav-item">

								<Link data-bs-dismiss="offcanvas" aria-label="Close" type="button" className={`nav-link text-black`} to={"#"}>
									Media and Press
									<svg className="ms-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.2796 5.72509C2.13253 5.87913 2.13194 6.12139 2.27826 6.27614L7.07417 11.3483C7.3224 11.6108 7.76393 11.4358 7.76481 11.0745L7.78944 0.95353C7.79032 0.59222 7.34965 0.415005 7.10014 0.67633L2.2796 5.72509Z" fill="#282828" />
									</svg>
								</Link>

							</li>
							<li className="nav-item">

								<Link data-bs-dismiss="offcanvas" onClick={() => navigate("/articles", { replace: true })} aria-label="Close" type="button" className={`nav-link text-black`} to={"/articles"}>
									Articles
									<svg className="ms-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.2796 5.72509C2.13253 5.87913 2.13194 6.12139 2.27826 6.27614L7.07417 11.3483C7.3224 11.6108 7.76393 11.4358 7.76481 11.0745L7.78944 0.95353C7.79032 0.59222 7.34965 0.415005 7.10014 0.67633L2.2796 5.72509Z" fill="#282828" />
									</svg>
								</Link>

							</li>
							<li className="nav-item">

								<Link data-bs-dismiss="offcanvas" onClick={() => navigate("/faq", { replace: true })} aria-label="Close" type="button" className={`nav-link text-black`} to={"/faq"}>
									FAQs
									<svg className="ms-3" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M2.2796 5.72509C2.13253 5.87913 2.13194 6.12139 2.27826 6.27614L7.07417 11.3483C7.3224 11.6108 7.76393 11.4358 7.76481 11.0745L7.78944 0.95353C7.79032 0.59222 7.34965 0.415005 7.10014 0.67633L2.2796 5.72509Z" fill="#282828" />
									</svg>
								</Link>

							</li>
						</ul>
						<div className="my-5 d-flex ps-5">
							<a href="https://www.quickteller.com/lender/quickfund" className="btn py-2 btn-outine-dark me-3 d-none d-lg-inline px-3 fw-bolder flex-1" style={{ border: "2px solid", borderRadius: "10px", fontSize: "12px", flex: "1" }}> <span className=""> GET INSTANT LOAN </span> </a>
							<a href="https://ibank.mybankone.com/quickfundmfb" className="btn banking-btn py-2 btn-dark fw-light d-none d-lg-inline px-3 text-light flex-1" style={{ borderRadius: "10px", fontSize: "12px", flex: "1" }}> <span > ONLINE BANKING </span> </a>
						</div>
						<div className="my-5 text-end">
							<a href="https://www.facebook.com/quickfundng/" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.0012 2.50293C8.09869 2.50293 2.50244 8.09918 2.50244 15.0017C2.50244 21.2392 7.07244 26.4092 13.0487 27.3504V18.6154H9.87369V15.0017H13.0487V12.2479C13.0487 9.11293 14.9149 7.38418 17.7687 7.38418C19.1362 7.38418 20.5687 7.62793 20.5687 7.62793V10.7017H18.9887C17.4387 10.7017 16.9537 11.6667 16.9537 12.6554V14.9992H20.4174L19.8637 18.6129H16.9537V27.3479C22.9299 26.4117 27.4999 21.2404 27.4999 15.0017C27.4999 8.09918 21.9037 2.50293 15.0012 2.50293Z" fill="#0A0A0A" />
									<path d="M15.0012 2.50293C8.09869 2.50293 2.50244 8.09918 2.50244 15.0017C2.50244 21.2392 7.07244 26.4092 13.0487 27.3504V18.6154H9.87369V15.0017H13.0487V12.2479C13.0487 9.11293 14.9149 7.38418 17.7687 7.38418C19.1362 7.38418 20.5687 7.62793 20.5687 7.62793V10.7017H18.9887C17.4387 10.7017 16.9537 11.6667 16.9537 12.6554V14.9992H20.4174L19.8637 18.6129H16.9537V27.3479C22.9299 26.4117 27.4999 21.2404 27.4999 15.0017C27.4999 8.09918 21.9037 2.50293 15.0012 2.50293Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="https://instagram.com/quickfundmfb?igshid=YmMyMTA2M2Y=" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.9999 11.0946C12.8495 11.0946 11.0946 12.8495 11.0946 14.9999C11.0946 17.1503 12.8495 18.9052 14.9999 18.9052C17.1503 18.9052 18.9052 17.1503 18.9052 14.9999C18.9052 12.8495 17.1503 11.0946 14.9999 11.0946ZM26.7128 14.9999C26.7128 13.3827 26.7274 11.7802 26.6366 10.1659C26.5458 8.29091 26.1181 6.62685 24.747 5.25575C23.3729 3.88173 21.7118 3.45693 19.8368 3.36611C18.2196 3.27529 16.6171 3.28993 15.0028 3.28993C13.3856 3.28993 11.7831 3.27529 10.1688 3.36611C8.29384 3.45693 6.62978 3.88466 5.25868 5.25575C3.88466 6.62978 3.45986 8.29091 3.36904 10.1659C3.27822 11.7831 3.29286 13.3856 3.29286 14.9999C3.29286 16.6142 3.27822 18.2196 3.36904 19.8339C3.45986 21.7089 3.88759 23.3729 5.25868 24.744C6.63271 26.1181 8.29384 26.5429 10.1688 26.6337C11.786 26.7245 13.3886 26.7099 15.0028 26.7099C16.62 26.7099 18.2225 26.7245 19.8368 26.6337C21.7118 26.5429 23.3759 26.1151 24.747 24.744C26.121 23.37 26.5458 21.7089 26.6366 19.8339C26.7304 18.2196 26.7128 16.6171 26.7128 14.9999ZM14.9999 21.0087C11.6747 21.0087 8.9911 18.3251 8.9911 14.9999C8.9911 11.6747 11.6747 8.99111 14.9999 8.99111C18.3251 8.99111 21.0087 11.6747 21.0087 14.9999C21.0087 18.3251 18.3251 21.0087 14.9999 21.0087ZM21.2548 10.1483C20.4784 10.1483 19.8515 9.52138 19.8515 8.74501C19.8515 7.96865 20.4784 7.34169 21.2548 7.34169C22.0311 7.34169 22.6581 7.96865 22.6581 8.74501C22.6583 8.92936 22.6222 9.11195 22.5517 9.28231C22.4813 9.45268 22.3779 9.60747 22.2476 9.73783C22.1172 9.86818 21.9624 9.97154 21.7921 10.042C21.6217 10.1124 21.4391 10.1486 21.2548 10.1483Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="https://twitter.com/quickfundmfb1?t=KvKU4_mb0MbO7N7dIFpv0g&s=09" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15 1.875C7.75195 1.875 1.875 7.75195 1.875 15C1.875 22.248 7.75195 28.125 15 28.125C22.248 28.125 28.125 22.248 28.125 15C28.125 7.75195 22.248 1.875 15 1.875ZM21.3076 11.7686C21.3164 11.9062 21.3164 12.0498 21.3164 12.1904C21.3164 16.4912 18.041 21.4453 12.0557 21.4453C10.21 21.4453 8.49902 20.9092 7.05762 19.9863C7.32129 20.0156 7.57324 20.0273 7.84277 20.0273C9.36621 20.0273 10.7666 19.5117 11.8828 18.6387C10.4531 18.6094 9.25195 17.6719 8.8418 16.3828C9.34277 16.4561 9.79395 16.4561 10.3096 16.3242C9.57342 16.1747 8.91174 15.7749 8.43696 15.1927C7.96218 14.6106 7.70357 13.8821 7.70508 13.1309V13.0898C8.13574 13.333 8.64258 13.4824 9.17285 13.5029C8.72708 13.2058 8.3615 12.8034 8.10853 12.3311C7.85556 11.8589 7.72302 11.3316 7.72266 10.7959C7.72266 10.1895 7.88086 9.63574 8.16504 9.15527C8.98214 10.1611 10.0017 10.9838 11.1576 11.5698C12.3135 12.1558 13.5797 12.4921 14.874 12.5566C14.4141 10.3447 16.0664 8.55469 18.0527 8.55469C18.9902 8.55469 19.834 8.94727 20.4287 9.58008C21.1641 9.44238 21.8672 9.16699 22.4941 8.79785C22.251 9.55078 21.7412 10.1865 21.0645 10.5879C21.7207 10.5176 22.3535 10.3359 22.9395 10.0811C22.4971 10.7314 21.9434 11.3086 21.3076 11.7686Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.4046 14.7491L14.0356 13.1771C13.7416 13.0406 13.5001 13.1936 13.5001 13.5191V16.4801C13.5001 16.8056 13.7416 16.9586 14.0356 16.8221L17.4031 15.2501C17.6986 15.1121 17.6986 14.8871 17.4046 14.7491ZM15.0001 0.599609C7.0471 0.599609 0.600098 7.04661 0.600098 14.9996C0.600098 22.9526 7.0471 29.3996 15.0001 29.3996C22.9531 29.3996 29.4001 22.9526 29.4001 14.9996C29.4001 7.04661 22.9531 0.599609 15.0001 0.599609ZM15.0001 20.8496C7.6291 20.8496 7.5001 20.1851 7.5001 14.9996C7.5001 9.81411 7.6291 9.14961 15.0001 9.14961C22.3711 9.14961 22.5001 9.81411 22.5001 14.9996C22.5001 20.1851 22.3711 20.8496 15.0001 20.8496Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15 1.875C7.75195 1.875 1.875 7.75195 1.875 15C1.875 22.248 7.75195 28.125 15 28.125C22.248 28.125 28.125 22.248 28.125 15C28.125 7.75195 22.248 1.875 15 1.875ZM22.5 9.30762L21.3047 10.4531C21.1992 10.5322 21.1494 10.6611 21.1699 10.7871V19.2158C21.1494 19.3447 21.1992 19.4736 21.3047 19.5498L22.4766 20.6953V20.9502H16.5938V20.707L17.8037 19.5322C17.9238 19.4121 17.9238 19.377 17.9238 19.1982V12.3779L14.5547 20.9209H14.1006L10.1807 12.3779V18.1055C10.1455 18.3457 10.2305 18.5889 10.4004 18.7617L11.9766 20.6689V20.9238H7.5V20.6689L9.07617 18.7617C9.15922 18.6759 9.22102 18.5719 9.25663 18.4579C9.29224 18.344 9.30067 18.2233 9.28125 18.1055V11.4844C9.30176 11.2998 9.23144 11.1211 9.09082 10.9951L7.69043 9.30762V9.05273H12.041L15.3984 16.4209L18.3545 9.05859H22.5V9.30762Z" fill="#0A0A0A" />
								</svg>
							</a>
							<a href="#" className="text-black me-3">
								<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15.0001 0.599609C7.0471 0.599609 0.600098 7.04661 0.600098 14.9996C0.600098 22.9526 7.0471 29.3996 15.0001 29.3996C22.9531 29.3996 29.4001 22.9526 29.4001 14.9996C29.4001 7.04661 22.9531 0.599609 15.0001 0.599609ZM11.4751 20.9681H8.5591V11.5841H11.4751V20.9681ZM9.9991 10.4321C9.0781 10.4321 8.4826 9.77961 8.4826 8.97261C8.4826 8.14911 9.0961 7.51611 10.0366 7.51611C10.9771 7.51611 11.5531 8.14911 11.5711 8.97261C11.5711 9.77961 10.9771 10.4321 9.9991 10.4321ZM22.1251 20.9681H19.2091V15.7676C19.2091 14.5571 18.7861 13.7351 17.7316 13.7351C16.9261 13.7351 16.4476 14.2916 16.2361 14.8271C16.1581 15.0176 16.1386 15.2876 16.1386 15.5561V20.9666H13.2211V14.5766C13.2211 13.4051 13.1836 12.4256 13.1446 11.5826H15.6781L15.8116 12.8861H15.8701C16.2541 12.2741 17.1946 11.3711 18.7681 11.3711C20.6866 11.3711 22.1251 12.6566 22.1251 15.4196V20.9681Z" fill="#0A0A0A" />
								</svg>

							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


export default Navbar;