import React from "react";
import { GooglePlayIcon } from "../../../Assets/Icons";
import "./style.scss"


const GooglePlayButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = (props) => {
    return (
        <button className={`app-btn  bg-white btn border-2 me-lg-3 ${props.className}`}>
            <img width={22} height={22} className="me-2" src={GooglePlayIcon} style={{ position: "relative", left: -5 }} />
            <span className="small text-dark" style={{ position: "relative", left: -7 }}> Download for Android </span>
        </button>
    )
}

export default GooglePlayButton;