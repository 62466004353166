import { ShieldIcon } from "../../Assets/Icons";
import "./style.scss"

declare interface BenefitCardProps {
    title: string;
    icon: string;
    description: string;
    borderColor: string
}

const BenefitCard: React.FC<BenefitCardProps> = (props) => {
    const elStyle: React.CSSProperties = {
        position: "absolute",
        width: "7.6rem",
        height: "4.2rem",
        right: "-.45rem",
        bottom: "-8px",
        backgroundColor: props.borderColor,
        zIndex: "-1",
        borderRadius: "8px",
    }

    return (
        <div className="benefit-card p-lg-3 p-2 position-relative bg-white relative" style={{ borderColor: props.borderColor, height: "170px" }}>
            <div className="content position-relative bg-white">
                <div className="d-flex justify-content-between mb-2">
                    <span className="title h4 fw-bolder fs-5">
                        {props.title}
                    </span>
                    <div>
                        <img src={props.icon} className="img" width="33px" />
                    </div>
                </div>
                <div>
                    <p className="description">
                        {props.description}
                    </p>
                </div>

            </div>
            <div style={elStyle}></div>

        </div>
    )
}

export default BenefitCard;
