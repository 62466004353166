import { CBNLogo, InterswitchLogo, FidelityLogo, AppZoneLogo, NibLogo, RimoLogo, _9MobileLogo, HealthMaxLogo, PaystackLogo } from "../../../Assets/Icons"

const TrustieSection = () => {
    const trustieLogs = [
        InterswitchLogo,
        NibLogo,
        FidelityLogo,
        AppZoneLogo,
        RimoLogo,
        HealthMaxLogo,
        PaystackLogo
    ]

    return (
        <div className="trustie-section py-5 px-2 mt-5 bg-dark">
            <div>
                <h4 className="text-white text-center mb-4">TRUSTED BY</h4>
                <div className="row px-3 d-none d-lg-flex justify-content-center">
                    <div className="col-lg-8 col-12 d-flex justify-content-around p-0 mt-2">
                        {trustieLogs.map((logo, index) => <img key={index} src={logo} className="img trustie-logo" width={96} height={96} />)}
                    </div>
                </div>
                <div className="row px- d-flex d-lg-none">
                    <div className="col-lg-8 col-12 d-flex justify-content-around p-0 mt-2">
                        {trustieLogs.map((logo, index) => <img key={index} src={logo} className="img trustie-logo" width={56} height={56} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TrustieSection;