
import { HTMLAttributes, useState } from "react";
import { Link } from "react-router-dom";
import { AboutLandingImg, SalaryManImg, BillsAndMoreLandingImg, IphoneImg, IphoneMock, VisionManImg, OracleLoanImg, SMELoanImg, BusinessWomanImg, OverdraftWomanImg, LoanAndOverdraftBgSm, ClientImg } from "../../Assets/Images";
import TestmonialCard from "../../Components/TestmonialCard";
import AppsSection from "../Components/AppsSection";
import Footer from "../Components/Footer";
import NewsLetterSection from "../Components/NewsLetterSection";
import NoteSection from "../Components/NoteSection";
import SocialMediaSection from "../Components/SocialMediaSection";
import AppStoreButton from "../Components/StoreButtons/AppStoreButton";
import GooglePlayButton from "../Components/StoreButtons/GooglePlayButton";
import USSDButton from "../Components/StoreButtons/USSDButton";
import WhatsAppButton from "../Components/StoreButtons/WhatsAppButton";
import "./style.scss"

declare interface LoanCardProps {
    id: number
    cardImg: string;
    cardTitle: string;
    description?: string;
    className?: string;
    selectedOption?: number;
    onChange?: (value: number) => void
}
const LoanCard: React.FC<LoanCardProps> = (props) => {

    const handleClick = () => {
        if (props.onChange) {
            props.onChange(props.id);
        }
    }

    return (
        <div className={`loan-card ${props.className}`}>
            <div>
                <img src={props.cardImg} width={228} alt="" className="img-fluid" style={{ cursor: "pointer" }} onClick={handleClick} />
            </div>
            <h6 className="cursor-pointer text-white text-center fw-medium mt-3" style={{ cursor: "pointer", userSelect: "none" }} onClick={handleClick}>{props.cardTitle}</h6>
        </div>
    );
}

const LoanCardSm: React.FC<LoanCardProps> = (props) => {
    const handleClick = () => {
        if (props.onChange) {
            props.onChange(props.id);
        }
    }
    return (
        <div className={`loan-card ${props.className} ${props.selectedOption == props.id ? "d-none" : ""}`}>
            <div>
                <img src={props.cardImg} width={200} alt="" className="w-100 img-fluid" />
            </div>
            <h6 className="text-left font-secondary mt-3 fw-bolder mt-1">{props.cardTitle}</h6>
            <p style={{ textAlign: "justify" }}>
                {props.description}
            </p>
            <Link to="#" onClick={handleClick} className="nav-link text-dark-teal mt-3 p-0">Learn more</Link>

        </div>
    );
}


declare interface LoanAndOverdraftLandingProps {
    onChange: (value: number) => void;
    selectedOption: number;
}

const LoanAndOverdraftLanding = (props: LoanAndOverdraftLandingProps) => {

    const handleSelectedOption = (value: number) => {
        props.onChange(value);
    }

    return (
        <>
            <div className="loan-and-overdraft-landing bg-black d-none d-lg-block py-5 px-5 position-relative" style={{ overflowX: "hidden" }}>
                <div className="d-flex justify-content-center flex-column flex-lg-row" style={{ columnGap: "100px" }}>
                    <img src={IphoneImg} className="img phone-img align-self-center" alt="" style={{ zIndex: 1 }} />
                    <div className="align-self-center text-white mb-4">
                        <h1 className="display-6 fw-bolder text-white font-primary text-center">
                            EASY AND SIMPLE LOAN PROCESS
                        </h1>
                        <p className="text-center fs-6">We have a range of products for your loan solutions</p>
                        <div className="mt-5">
                            <AppStoreButton className="mt-3 me-0" />
                            <GooglePlayButton className="me-2 mt-3" />
                            <WhatsAppButton className="me-2  mt-3" />
                            <USSDButton className=" m mt-3" />
                        </div>
                        <div className="mt-3">
                            <a href="https://www.quickteller.com/lender/quickfund" target="_blank" rel="noopener noreferrer" className={`app-btn  bg-white btn border-2 `}>
                                <svg className="me-2" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" fill="#83BF4F" />
                                    <path d="M9.85962 21.3574V10.0833C9.85955 9.84373 9.93906 9.61089 10.0857 9.42137C10.2323 9.23186 10.4377 9.09642 10.6696 9.03631C10.9016 8.9762 11.1469 8.99484 11.3671 9.08929C11.5873 9.18374 11.7699 9.34866 11.8862 9.55814L18.1309 20.7993C18.2472 21.0088 18.4298 21.1737 18.65 21.2682C18.8702 21.3626 19.1155 21.3812 19.3475 21.3211C19.5794 21.261 19.7848 21.1256 19.9314 20.9361C20.078 20.7466 20.1576 20.5137 20.1575 20.2741V9" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.80005 13.1191H22.2171" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.80005 17.2383H22.2171" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span className="small" style={{ position: "relative", left: -7 }}> Get Instant Loan </span>
                                {/* <span style={{ visibility: "hidden", left: -7 }}> Download for IOS </span> */}
                            </a>
                        </div>
                    </div>

                </div>
                <div className="d-flex mt-5 pt-4 flex-column flex-lg-row justify-content-around" style={{ columnGap: "12px" }}>
                    <LoanCard id={0} onChange={handleSelectedOption} className="align-self-center" cardImg={SalaryManImg} cardTitle={"Salary Advance Loan"} />
                    <LoanCard id={1} onChange={handleSelectedOption} className="align-self-center" cardImg={BusinessWomanImg} cardTitle={"Business Loan"} />
                    <LoanCard id={2} onChange={handleSelectedOption} className="align-self-center" cardImg={SMELoanImg} cardTitle={"SME Loan"} />
                    <LoanCard id={3} onChange={handleSelectedOption} className="align-self-center" cardImg={OverdraftWomanImg} cardTitle={"Overdraft"} />
                    <LoanCard id={4} onChange={handleSelectedOption} className="align-self-center" cardImg={OracleLoanImg} cardTitle={"Public Sector Loan"} />
                </div>

                <div className="position-absolute right-0" style={{ top: "-100px", right: "-100px" }}>
                    <svg width="629" height="571" viewBox="0 0 829 771" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M896.719 -202.95C981.844 -134.672 1021.37 -36.9854 1063.17 57.7805C1100.02 141.343 1135.61 226.753 1123.58 311.451C1111.68 395.24 1063.58 467.319 997.377 520.882C933.519 572.546 840.365 572.495 761.583 604.322C634.23 655.77 537.484 803.557 394.447 764.454C258.157 727.196 223.165 556.749 155.482 438.63C88.3776 321.521 -16.3982 204.048 2.8324 81.0158C21.9468 -41.2726 154.235 -102.961 252.221 -180.28C343.481 -252.292 426.138 -347.849 551.934 -352.274C675.713 -356.627 797.091 -282.86 896.719 -202.95Z" fill="#FCB5BD" fill-opacity="0.19" />
                    </svg>
                </div>
                <div className="position-absolute right-0" style={{ top: "-100px", left: "-20rem", transform: "scaleX(-1) scaleY(-1) rotate(90deg)" }}>
                    <svg width="629" height="571" viewBox="0 0 829 771" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M896.719 -202.95C981.844 -134.672 1021.37 -36.9854 1063.17 57.7805C1100.02 141.343 1135.61 226.753 1123.58 311.451C1111.68 395.24 1063.58 467.319 997.377 520.882C933.519 572.546 840.365 572.495 761.583 604.322C634.23 655.77 537.484 803.557 394.447 764.454C258.157 727.196 223.165 556.749 155.482 438.63C88.3776 321.521 -16.3982 204.048 2.8324 81.0158C21.9468 -41.2726 154.235 -102.961 252.221 -180.28C343.481 -252.292 426.138 -347.849 551.934 -352.274C675.713 -356.627 797.091 -282.86 896.719 -202.95Z" fill="#FCB5BD" fill-opacity="0.19" />
                    </svg>
                </div>
            </div>

            <div className="loan-and-overdraft-landing d-block d-lg-none pt-5 position-relative" style={{ overflowX: "hidden" }}>
                <div className="row pt-4 justify-content-between bg-black">
                    <div className="col-12 position-relative ">
                        {(() => {
                            switch (props.selectedOption) {
                                case 0: return <>
                                    <div className="d-flex  justify-content-around pt-5 px-2 flex-column flex-lg-row">
                                        <h3 className="font-secondary text-white text-center">Easy and Simple Loan Process</h3>
                                        <p className="text-white mt-3 fs-6 text-center">
                                            We have range of products for your financial solutions at competitive interest rate. Loan ranging from N10,000 - N2,000,000.
                                        </p>
                                        <img src={IphoneMock} className="img mt-4 phone-img align-self-center" alt="" style={{ zIndex: 1 }} />
                                    </div>
                                </>

                                case 1: return <img src={SalaryManImg} alt="loan image" style={{ opacity: 0.5, width: "75%" }} className="img img-fluid  pb-5 pt-3 mx-auto d-block" />
                                case 2: return <img src={BusinessWomanImg} alt="loan image" style={{ opacity: 0.5, width: "75%" }} className="img img-fluid  pb-5 pt-3 mx-auto d-block" />
                                case 3: return <img src={SMELoanImg} alt="loan image" style={{ opacity: 0.5, width: "75%" }} className="img img-fluid  pb-5 pt-3 mx-auto d-block" />
                                case 4: return <img src={OverdraftWomanImg} alt="loan image" style={{ opacity: 0.5, width: "75%" }} className="img img-fluid  pb-5 pt-3 mx-auto d-block" />
                                case 5: return <img src={OracleLoanImg} alt="loan image" style={{ opacity: 0.5, width: "75%" }} className="img img-fluid  pb-5 pt-3 mx-auto d-block" />
                            }
                        })()}
                        {

                            props.selectedOption ?
                                <svg className="position-absolute" style={{ height: "95%", top: -8, right: 36 }} height="344" viewBox="0 0 206 344" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.146606 2H184.5C195.546 2 204.5 10.9543 204.5 22V322.589C204.5 333.635 195.546 342.589 184.5 342.589H72.3134C65.7364 342.589 60.4047 337.257 60.4047 330.68V330.68" stroke="white" stroke-width="2.5" />
                                </svg>

                                : <></>
                        }
                    </div>

                </div>

                <div className="container mt-5">
                    {(() => {
                        switch (props.selectedOption) {
                            case 1: return <SalarayAdvanceLoan />
                            case 2: return <BusinessLoan />
                            case 3: return <SmeLoan />
                            case 4: return <Overdrafts />
                            case 5: return <PublicSectorLoan />
                        }

                    })()}
                </div>

            </div>
        </>
    )
}

const TestimonialSection = () => {
    return (
        <div className="d-flex justify-content-center px-5 mt-5 mt-5 py-5 border-top">
            <div className="col-lg-6 px-lg-3 col-md-8 col-sm-11 col-12 py-4">
                <div className="bg-white testimonial-card position-relative rounded-4 shadow p-4">
                    <div className="client-img relative mb-4 d-flex justify-content-center">
                        <img src={ClientImg} className="img" height={55} />
                    </div>
                    <div className="client-name text-center">
                        <h5 className="m-0 p-0 fw-bolder fs-6">Oluwakemi Joachim</h5>
                        <h6 className="text-secondary" style={{ fontSize: "11px" }}>
                            Lagos, Nigeria
                        </h6>
                    </div>
                    <div className="client-comment mt-4 text-center" >
                        <p className="fw-bolder text-start" style={{ fontSize: "0.9rem", lineHeight: 1.8 }}>
                            We don’t delay! At QuickFund, we grant quick approvals to loan application once you provide us with every necessary information and requirements.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Overdrafts = () => {
    return (
        <>
            <h3 className="fw-bolder font-primary text-dark-teal">
                OVERDRAFTS
            </h3>
            <p className="detail lh-base fw-bold mb-0">
                No more insufficient funds, QuickFund has got you! We offer an overdraft facility that allows you to use
                or withdraw more money than what you have in your account up to the approved limit.
                Perchance you or your business is experiencing issues with cash inflow at the moment, we know hard
                time doesn’t last. Our overdraft facility will help you till you get back on track.
            </p>
            <div className="mt-4">
                <a href="tel:+2349090512482" className="h4 mt-4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3" style={{ textDecoration: 'none' }}>
                    CALL +234 909 051 2482
                </a>
            </div>
        </>
    )
}

const SmeLoan = () => {
    return (
        <>
            <h3 className="fw-bolder font-primary text-dark-teal">
                SME LOANS
            </h3>
            <p className="detail lh-base fw-bold mb-0">

                Do you need working capital for your business, want to acquire new office equipment or have other
                business needs? We have the right loan to cater to your needs with a low-interest rate. The SME Loan is
                available to small and medium scale businesses to help meet working capital needs and to accomplish
                other business goals.QuickFund offers support to small businesses. The SME loan product is eligible for business owners whether registered or
                to help them grow their existing business.
            </p>

            <div className="benefit-and-features mt-5">
                <span className="h4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3">
                    Features and Requirements
                </span>
                <div className="mt-4">
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>1. 3 passport photographs</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>2. Recent utility bill</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>3. Federal Govt. issued ID card</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>4. 6 post-dated cheques</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>5. 6 months recent statement of account</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>6. 2 guarantors</p>


                </div>

                <div className="mt-4">
                    <a href="tel:+2349090512482" className="h4 mt-4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3" style={{ textDecoration: 'none' }}>
                        CALL +234 909 051 2482
                    </a>
                </div>
            </div>
        </>
    )
}

const BusinessLoan = () => {
    return (
        <>
            <h3 className="fw-bolder font-primary text-dark-teal">
                BUSINESS LOAN
            </h3>
            <p className="detail lh-base fw-bold mb-0">

                Starting a business involves an adequate amount of capital to fund startup expenses, and existing
                businesses also need sufficient funds to pay for growth and expansions. Our business loan is designed to
                allow companies have a chance at success. In essence, with QuickFund business loans, we offer
                businesses the financial assistance needed at any time.
            </p>

            <div className="benefit-and-features mt-5">
                <span className="h4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3">
                    Features and Requirements
                </span>
                <div className="mt-4">
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>1. Company's CAC registration document</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>2. Director's ID card, passport photograph</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>3. Recent utility bill</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>4. Board resolution for account opening</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>5. Board resolution for loan request</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>6. TIN</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>7. 6 months recent statement of account</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>8. 6 post-dated cheques</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>9. 2 guarantors</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>10. Collateral</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>11. Access up to 5 million naira</p>
                </div>

                <div className="mt-4">
                    <a href="tel:+2349090512482" className="h4 mt-4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3" style={{ textDecoration: 'none' }}>
                        CALL +234 909 051 2482
                    </a>
                </div>
            </div>
        </>
    )
}

const SalarayAdvanceLoan = () => {
    return (
        <>
            <h3 className="fw-bolder font-primary text-dark-teal">
                SALARY ADVANCE
            </h3>
            <p className="detail lh-base fw-bold mb-0">
                Irrespective of pay, we all often run a little short on funds from time to time. We end up wishing we
                could get our salary before the payday. Fortunately, QuickFund is around to rescue with salary advance.
                You get an easy and fast loan of up to 200% of your salary from QuickFund with a convenient repayment
                plan. There is instant disbursement of salary advance loan to make sure it covers emergency cases.
            </p>

            <div className="benefit-and-features mt-5">
                <span className="h4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3">
                    Features and Requirements
                </span>
                <div className="mt-4">
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>1. Staff ID card</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>2. Federal Govt. issued ID card</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>3. Recent utility bill</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>4. 3 passport photograph</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>5. 6 months recent statement of salary account</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>6. 2 guarantors</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>7. Paystack account actiavtion</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>8. 6 post-dated cheque leaves</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>9. No papaer work</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}>10. Access up to 2 million naira</p>
                </div>

                <div className="mt-4">
                    <a href="tel:+2349090512482" className="h4 mt-4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3" style={{ textDecoration: 'none' }}>
                        CALL +234 909 051 2482
                    </a>
                </div>
            </div>
        </>
    )
}

const PublicSectorLoan = () => {
    return (
        <>
            <h3 className="fw-bolder font-primary text-dark-teal">
                Public Sector Loan
            </h3>
            <p className="detail lh-base fw-bold mb-0">
                As a government worker, we believe you deserve the right to a credit facility. Irrespective of your status,
                your desire for quick-support loan is what we are here to satisfy. If you are a public worker with a unique
                ID (Oracle Number), you can apply for a loan of up to 1 million naira.
            </p>
            <div className="benefit-and-features mt-5">
                <span className="h4  rounded-3 fw-bolder fs-6 bg-light text-dark-teal p-2 px-3">
                    Features and Requirements
                </span>
                <div className="mt-4">
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1  "></i>No Collateral</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1"></i>Access up to 1Million naira</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1"></i>No Collateral</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1"></i>No Collateral</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1"></i>No Paper work</p>
                    <p className="fw-bold mb-1" style={{ fontSize: "0.8rem" }}><i className="bi bi-caret-right-fill text-red me-1"></i>Disbursement within 24hrs</p>
                </div>
            </div>
            <div className="mt-5">
                <button className="btn px-3 py-2 fw-bolder" style={{ backgroundColor: "#85F11A" }}> Apply Now</button>
            </div>
        </>
    )
}


declare interface LoanSectionProps {
    selectedOption: number;
    onChange: (value: number) => void;
}

const LoanSection = (props: LoanSectionProps) => {

    const handleSelectedOption = (value: number) => {
        props.onChange(value);
    }

    return (
        <>
            <div className="loan-section mt-5 d-none d-lg-block container">
                <div className="row py-4 justify-content-between">
                    <div className="col-12 col-lg-6 py-lg-5 mb-4">
                        {(() => {
                            switch (props.selectedOption) {
                                case 0: return <SalarayAdvanceLoan />
                                case 1: return <BusinessLoan />
                                case 2: return <SmeLoan />
                                case 3: return <Overdrafts />
                                case 4: return <PublicSectorLoan />
                            }

                        })()}
                    </div>
                    <div className="col-12 col-lg-5 mt-4 text-center position-relative">
                        <div className="position-relative">
                            {(() => {
                                switch (props.selectedOption) {
                                    case 0: return <img src={SalaryManImg} alt="loan image" style={{ opacity: 0.5 }} className="img img-fluid w-100" />
                                    case 1: return <img src={BusinessWomanImg} alt="loan image" style={{ opacity: 0.5 }} className="img img-fluid w-100" />
                                    case 2: return <img src={SMELoanImg} alt="loan image" style={{ opacity: 0.5 }} className="img img-fluid w-100" />
                                    case 3: return <img src={OverdraftWomanImg} alt="loan image" style={{ opacity: 0.5 }} className="img img-fluid w-100" />
                                    case 4: return <img src={OracleLoanImg} alt="loan image" style={{ opacity: 0.5 }} className="img img-fluid w-100" />
                                }

                            })()}
                            <svg className="position-absolute" style={{ height: "110%", top: -20, right: -70 }} width="432" height="720" viewBox="0 0 432 720" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 2.5H409.5C420.546 2.5 429.5 11.4543 429.5 22.5V697.5C429.5 708.546 420.546 717.5 409.5 717.5H147C135.954 717.5 127 708.546 127 697.5V692.5" stroke="black" stroke-width="5" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="loan-section mt-1 d-block d-lg-none">
                {
                    props.selectedOption ? <div className="mt-5 container fw-bold fs-4">
                        You may also be interested in:
                    </div>
                        :
                        <></>
                }
                <div className="bg-light px-4 py-4">
                    <LoanCardSm onChange={handleSelectedOption} id={1} selectedOption={props.selectedOption} className="align-self-center" cardImg={SalaryManImg} cardTitle={"Salary Advance Loan"} description="Irrespective of pay, we all often run a little short on funds from time to time. We end up wishing we could get our salary before the payday. Fortunately, QuickFund is around to rescue with salary advance." />
                    <LoanCardSm onChange={handleSelectedOption} id={2} selectedOption={props.selectedOption} className="align-self-center mt-4" cardImg={BusinessWomanImg} cardTitle={"Business Loan"} description=" Starting a business involves an adequate amount of capital to fund startup expenses, and existing businesses also need sufficient funds to pay for growth and expansions." />
                    <LoanCardSm onChange={handleSelectedOption} id={3} selectedOption={props.selectedOption} className="align-self-center mt-4" cardImg={SMELoanImg} cardTitle={"SME Loan"} description="Do you need working capital for your business, want to acquire new office equipment or have other business needs? We have the right loan to cater to your needs with a low-interest rate." />
                    <LoanCardSm onChange={handleSelectedOption} id={4} selectedOption={props.selectedOption} className="align-self-center mt-4" cardImg={OverdraftWomanImg} cardTitle={"Overdraft"} description="No more insufficient funds, QuickFund has got you! We offer an overdraft facility that allows you to use or withdraw more money than what you have in your account up to the approved limit." />
                    <LoanCardSm onChange={handleSelectedOption} id={5} selectedOption={props.selectedOption} className="align-self-center mt-4" cardImg={OracleLoanImg} cardTitle={"Public Sector Loan"} description="As a government worker, we believe you deserve the right to a credit facility. Irrespective of your status, your desire for quick-support loan is what we are here to satisfy. If you are a public worker with a unique ID (Oracle Number), you can apply for a loan of up to 1 million naira." />
                </div>
            </div>
        </>
    )
}

const LoanAndOverdraft = () => {

    const [selectedMenu, setSelectedMenu] = useState(0);

    const handleChange = (value: number) => {
        setSelectedMenu(value)
    }

    return (
        <div className="loan-and-overdraft">

            <LoanAndOverdraftLanding selectedOption={selectedMenu} onChange={handleChange} />
            <LoanSection selectedOption={selectedMenu} onChange={handleChange} />
            <div className="d-none d-lg-block">
                <TestimonialSection />
            </div>
            <div className="d-block d-lg-none">
                <SocialMediaSection useDark />
            </div>
            <div className="d-block d-lg-none">
                <AppsSection />
            </div>
            {/* <NewsLetterSection /> */}
            <NoteSection />
            <Footer />
        </div>
    );
}

export default LoanAndOverdraft;